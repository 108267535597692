import type { Record } from '~dk/store'
import { forTarget, mount } from '~dk/core'
import { fromTemplate, replaceContents } from '~dk/content'
import { clearContents } from '~dk/helpers'
import moment from 'moment'
import { toUSD } from '~dk/formatters'
import { RootElement, watch } from '~dk/watch'
import api from '../lib/api'

interface Props {
  recentOrder: Record<any>
  checkoutUser: Record<any>
}

declare global {
  interface Window { dataLayer: any[] }
}

const deliveryModes = {
  curbside: 'Curbside Pickup',
  delivery: 'Delivery',
  dispatch: 'Dispatch',
  pickup: 'Pickup',
  dinein: 'Dine in',
  drivethru: 'Drive thru'
}

export default function (root: HTMLElement, { recentOrder, checkoutUser }: Props) {
  /**
   * Combines and formats the location and recent order data for use on the
   */
  async function createThankYouData () {
    try {
      const recentOrderData = recentOrder.getValue()
      const checkoutUserData = checkoutUser?.getValue()

      const { response } = await api.restaurants.get(recentOrderData.vendorid).toPromise()
      const locationData = response

      return {
        firstName: checkoutUserData?.first_name,
        lastName: checkoutUserData?.last_name,
        email: checkoutUserData?.communicable_email ||  checkoutUserData?.communicable_email,
        phoneNumber: checkoutUserData?.phone,
        deliveryMode: deliveryModes[recentOrderData.deliverymode],
        deliveryModeSlug: recentOrderData.deliverymode,
        telephone: locationData.telephone,
        eta: moment(recentOrderData.readytime, 'YYYYMMDD HH:mm').format('hh:mm a [on] ll'),
        tax: toUSD(recentOrderData.taxes?.reduce((acc: number, tax: { tax: number }) => tax.tax + acc, 0)),
        orderNumber: recentOrderData.oloid,
        tipRaw: recentOrderData.tip, // for conditional on thanks page
        tip: toUSD(recentOrderData.tip), // delivery driver tip
        deliveryFeeRaw: recentOrderData.customerhandoffcharge, // for conditional on thanks page
        deliveryFee: toUSD(recentOrderData.customerhandoffcharge), // delivery fee
        serviceFeeRaw: recentOrderData.fees[0]?.amount || 0, // for conditional on thanks page
        serviceFee: toUSD(recentOrderData.fees[0]?.amount || 0), // 15% delivery surcharge
        subTotal: toUSD(recentOrderData.subtotal),
        total: toUSD(recentOrderData.total),
        discount: toUSD(recentOrderData.discounts.reduce((acc: number, discount: { amount: number }) => {
          return discount?.amount + acc
        }, 0)),
        address: locationData.streetaddress,
        city: locationData.city,
        state: locationData.state,
        zip: locationData.zip,
        deliveryAddress: recentOrderData.deliveryaddress?.streetaddress,
        deliveryCity: recentOrderData.deliveryaddress?.city,
        deliveryZipCode: recentOrderData.deliveryaddress?.zipcode,
      }
    } catch(error) {
      return null
    }
  }

  /**
   * Adds the product to the Thank You page
   *
   * @param baseElement
   */
  function setContentsForRegionOrder (baseElement: Element) {
    const recentOrderData = recentOrder.getValue()
    const container = forTarget<HTMLElement>(baseElement as RootElement, 'product-list-container')!
    clearContents(container as HTMLElement)

    for (const product of recentOrderData.products) {
      const template = fromTemplate('product', {...product, totalcost: toUSD(product.totalcost)})!
      container.appendChild(template)
    }
  }

  /**
   * pushDataToDataLayer
   *
   * pushes data to GA's dataLayer
   * @param data
   */
  function pushDataToDataLayer (data) {
    const numberify = (n: Maybe<string>) => +(n?.replace('$', '') || 0)
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      'event': 'orderConfirmation',
      'orderNumber': data.orderNumber,
      'subtotal': numberify(data.subTotal),
      'total': numberify(data.total),
      'tax': numberify(data.tax),
      'discount': numberify(data.discount),
      'firstName': data.firstName,
      'lastName': data.lastName,
      'customerEmail': data.email,
      'phone': data.phoneNumber,
      'deliveryAddress': data.deliveryAddress,
      'deliveryCity': data.deliveryCity,
      'deliveryZipCode': data.deliveryZipCode,
    })

    /**
     * Google Universal Analytics purchase event
     * @link https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_purchases
     */
    gtag('event', 'purchase', {
      "transaction_id": data.orderNumber,
      "affiliation": data.city + ', ' + data.state,
      "value": numberify(data.total),
      "currency": "USD",
      "tax": numberify(data.tax),
    })
  }

  /**
   * Generates and displays the thank you page content
   */
  async function displayOrderInformation () {
    const container = forTarget<HTMLElement>(root, 'thank-you-container')!
    const thankYouData = await createThankYouData()

    if (!thankYouData) return
    pushDataToDataLayer(thankYouData)
    forTarget<HTMLLinkElement>(root, 'phone-button')!.href = 'tel:+1' + thankYouData.telephone.replace(/([\s()-])/g, '')
    setCurbsideNotice(!!eval('thankYouData.deliveryMode == "Curbside Pickup"'))

    const template = fromTemplate('thank-you', thankYouData)!
    clearContents(container)
    setContentsForRegionOrder(template)
    container.appendChild(template)

  }

  /**
   * Show/hide curbside notice
   * @param condition 
   */
  function setCurbsideNotice (condition: boolean) {
    const container = forTarget<HTMLElement>(root, 'curbside-notice')
    if (container) {
      container.style.display = condition ? 'block' : 'none'
    }
    watchForCurbsideArrival()
  }

  /**
   * Watch for curbside arrival click
   */
  function watchForCurbsideArrival () {

    const recentOrderData = recentOrder.getValue()
    const thankYouContainer = forTarget<HTMLElement>(root, 'thank-you-container')!
    
    thankYouContainer.addEventListener('click', async(event) => {
      const { target } = event
      if (target && ((target as HTMLElement).id === 'curbsideCheckin')) {
        console.log('Send arrival info to Olo.')
        const payload = {
          message: 'Customer has arrived'
        }
        const { response } = await api.orders.arrivalNotice(recentOrderData.id, payload).toPromise()
        if (response && response.arrivalstatus === 'Arrived') {
          window.alert('Got it! Your order will be out shortly.');
          replaceContents(thankYouContainer, 'curbside-notice', 'Thanks for letting us know you’re here. We’ll be out with your order shortly.')
        }
      }
    })
  }

  return {
    start: mount(root,
      displayOrderInformation
    )
  }
}
