import findWhere from 'lodash.findwhere'
import take from 'lodash.take'
import { mount } from '~dk/core'
import { diddleContents } from '~dk/content'
import { fetchMenu, handleBowlButton, setBowlContents } from '../utils/menu'
import type { Record } from '~dk/store'
import { findOrCreateOloBasket } from '../lib/shared'

type Props = {
  menu: Record<any>,
  location: Record<any>,
  basket: Record<any>,
  account: Record<any>,
}

export default function (root: HTMLElement, { menu, location, basket, account }: Props) {
  const handleBowlButtonClicks = () => handleBowlButton(location, basket, account, root)

  /**
   * Get 3 random bowls + the custom bowl from categories
   *
   * @param data
   */
  function getBowlsFromMenu (data) {
    const { products } = findWhere(data.categories, { name: 'Chef Bowls' })

    return take(products, 4)
  }

  /**
   * Adds each bowl to the quick order container on the home page
   *
   * @param data
   */
  async function addBowlsToQuickOrderContainer (data) {
    const cards = getBowlsFromMenu(data).map((bowl) => setBowlContents(location, basket, bowl, data))

    diddleContents(root, 'quick-order-container', ...cards)
    await handleBowlButtonClicks()
  }

  function subscribeToMenu () {
    menu.subscribe(async (data) => {
      if (!data) return fetchMenu(location, menu)
      await addBowlsToQuickOrderContainer(data)
    })
  }

  function subscribeToLocation () {
    location.subscribe(async (data) => {
      if (data) {
        await findOrCreateOloBasket(location, basket, account)
        fetchMenu(location, menu)
      }
    })
  }

  return {
    start: mount(root,
      subscribeToMenu,
      subscribeToLocation
    )
  }
}
