import App from './app'
import 'regenerator-runtime/runtime'
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

const LOCAL_API_URL = process.env.LOCAL_API_URL
const isStaging = window.location.host.match(/webflow.io/)

if (isStaging && !window.__DK__) {
  $.getScript(`${LOCAL_API_URL}/index.js`)
    .done(() => {
      process.env.NODE_ENV === 'production' || console.log('%c 🐒 DK Started', 'color: gold; font-size: 32px; font-weight: bold')
      window.__DK__ = true
    })
    .fail(App)
} else {
  App()
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})

export default null

