import type { Record } from '~dk/store'
import { forTarget, mount, on } from '~dk/core'
import api from '../lib/api'
import { clearAlert, showAlert } from '../lib/shared'

interface Props {
  basket: Record<any>
}

export default function (root: HTMLElement, { basket }: Props) {

  basket.subscribe((data) => {
    showAddedCoupon()
  })

  /**
   * Handle coupon entry
   */
  async function addCoupon (event, _, target) {
    const form = forTarget<HTMLElement>(root, 'checkout-form')!
    const basketData = basket.getValue()
    const couponcode = target.value

    clearAlert(form)
    if (couponcode.trim() === '') return // If the coupon code value is blank, do nothing

    api.baskets.applyCouponToBasket(basketData.id, couponcode).subscribe(
      ({ response }) => basket.next(response),
      ({ response }) => showAlert(form, response.message)
    )
  }
  
  /**
   * Show added coupon, if any, to user
   */
  async function showAddedCoupon () {
    const basketData = basket.getValue()
    const addedCoupon = forTarget<HTMLElement>(root, 'added-coupon')!
    if (!addedCoupon) return
    if (basketData.coupon) {
      addedCoupon.style.display = 'flex'
      const addedCouponCode = forTarget<HTMLElement>(root, 'added-coupon-code')!
      addedCouponCode.innerHTML = 'Code ' + basketData.coupon.couponcode + ' added'
    } else {
      addedCoupon.style.display = 'none'
    }
  }
  
  /**
   * Remove coupon
   */
  async function removeCoupon (event) {
    event.preventDefault()
    const basketData = basket.getValue()
    const form = forTarget<HTMLElement>(root, 'checkout-form')!
    api.baskets.removeCouponFromBasket(basketData.id).subscribe(
      ({ response }) => handleRemovingCoupon(response),
      ({ response }) => showAlert(form, response.message),
      () => console.log('Removed coupon')
    )
  }

  /**
   * Handle removing the coupon along with other stuff
   */
  async function handleRemovingCoupon (response) {
    basket.next(response)
    const addedCoupon = forTarget<HTMLElement>(root, 'added-coupon')!
    addedCoupon.style.display = 'none'
    const couponInput = <HTMLInputElement>document.querySelector('[dk-input="coupon-code"]')!
    couponInput.value = '';
  }

  return {
    start: mount(root,
      on('apply-coupon', 'focusout', addCoupon),
      on('remove-coupon', 'click', removeCoupon),
      showAddedCoupon
    )
  }
}
