import api, { ProductPayload } from '../lib/api'
import { Record } from '~dk/store'
import { on } from '~dk/core'
import { findOrCreateOloBasket, getImageByGroupName, getOrSetLocation, resizeOloImage } from '../lib/shared'
import { fromEvent } from 'rxjs'
import { fromTemplate } from '~dk/content'
import { URLSearchParams } from 'url'

/**
 * Fetches the modifiers for a product and appends it
 *
 * @param product
 */
export async function getModifiersForProduct(product)  {
  const { response } = await api.products.modifiers(product.id).toPromise()
  product.modifiers = response.optiongroups

  return product
}

/**
 * If the URL contains the search param 'location' find and set that location
 * 
 */
export function updateLocationIfSearchParamExists(location: Record<any>) {
  const searchParams = new window.URLSearchParams(window.location.search)
  const storeNumber = searchParams.get('location')

  if (storeNumber) {
    api.restaurants.getByRef(storeNumber).subscribe(({ response }) => {
      const locationData = response.restaurants?.[0]
      location.next(locationData)
    })
  }

}

/**
 * Fetches the menu from the API
 */
export function fetchMenu (location: Record<any>, menu: Record<any>, callback?: Function) {
  const locationID = (location.getValue() as any)?.id

  api.restaurants.menu(locationID).subscribe(async ({ response }) => {
    // const menuData = await addModifiersToBowls(response)
    menu.next(response)
    if (callback) callback()
  })
}

/**
 * Handles the size buttons on bowls
 */
export async function handleBowlButton (location, basket, account, root) {
  on('bowl-button', 'click', async (_event, _value, target: any) => {
    if (!location.getValue()) {
      await findOrCreateOloBasket(location, basket, account)
      return
    }

    if (target.attributes.open) {
      target.style.transition = 'all 200ms ease-in'
      target.style.width = '100%'
      target.attributes.open = false
      setTimeout(() => {
        target.innerHTML = 'ADD TO BAG'
      }, 200)
    } else {
      target.innerHTML = '&nbsp;'
      target.style.width = '0px'
      target.attributes.open = true
    }
  })(root)
}

/**
 *
 * @param size
 * @param product
 * @param basket
 */
export async function addBowlToBasket(size, product, basket) {
  const data = basket.getValue()
  if (!data) return;
  const { id } = data as any;

  // cycle through each sub option and choose the default
  const options = size.modifiers
    .map(({ mandatory, options }) => {
      const defaultOption = options.find((option) => option.isdefault);
      if (defaultOption) return defaultOption;
      if (mandatory) return options[0];
    })
    .filter(Boolean)
    .map((option) => option.id)
    .concat(size.id)
    .join(',')

  const payload: ProductPayload = {
    productid: product.id,
    quantity: 1,
    options,
  }

  const { response } = await api.baskets.addItemToBasket(id, payload).toPromise()
  basket.next(response)
}

/**
 * Sets the add to bag actions for each size of a bowl
 *
 * @param card
 * @param size
 * @param bowl
 * @param basket
 * @param location
 */
function handleQuickOrderForBowlSizes (card: HTMLDivElement, size: { name: string }, bowl: any, basket: any, location: any) {
  const buttons = card.querySelectorAll(`[dk-target="bowl-size"]`)

  const bowlButtons = Array.from(buttons).filter((button) => {
    const matcher = button.getAttribute('match')!
    return size.name.match(new RegExp(matcher, 'i'))
  }) as HTMLDivElement[]

  for (const button of bowlButtons) {
    if (!bowl || button.style.display) return
    button.style.display = 'flex'

    fromEvent(button, 'click')
      .subscribe(async () => {
        await getOrSetLocation(location)
        await addBowlToBasket(size, bowl, basket)
      })
  }
}

/**
 * Updates the product card's attributes and adds the click listeners for
 * navigating to the product, or add a bowl and it's defaults to the bag
 *
 * TODO: This takes too many parameters, refactor this
 *
 * @param card
 * @param product
 * @param type
 * @param location
 * @param basket
 */
export function setCardAttributesForBowl (card: HTMLDivElement, product, type: string, location, basket) {
  if (type !== 'bowl') return
  const customizeBowlLinks = card.querySelectorAll(`[dk-target="customize-bowl"]`)!
  let bowl: any
  let contentSizesSet = false

  async function setContentSizes () {
    if (contentSizesSet) return

    bowl ||= await getModifiersForProduct(product)
    card.querySelector(`[dk-target="loading-message"]`)?.remove() // remove the loading div
    bowl.modifiers[0].options.forEach((size) => handleQuickOrderForBowlSizes(card, size, bowl, basket, location))

    const price = card.querySelector('[dk-value="cost"]')!;
    price.innerHTML = '$' + (bowl.modifiers[0].options[0].cost || 0).toFixed(2);

    contentSizesSet = true
  }

  setContentSizes()

  // Cheeky little trick to cut down on loading times
  fromEvent(card, 'mouseenter').subscribe(async () => await setContentSizes())
  fromEvent(card.querySelector('[dk-action="bowl-button"]')!, 'click').subscribe(setContentSizes)
  fromEvent(card.querySelector('[dk-action="show-mobile-buttons"]')!, 'click').subscribe(setContentSizes)

  for (const link of Array.from(customizeBowlLinks)) {
    fromEvent(link, 'click').subscribe(async (event) => {
      event.preventDefault()
      await navigateToProduct(location, bowl.id)
    })
  }
}

/**
 * If the a location is set, navigate to the product. If not, pop that modal. :)
 *
 * @param location
 * @param value
 */
export async function navigateToProduct (location, value: Maybe<string>) {
  await getOrSetLocation(location)
  window.location.href = `/product?id=${value}`
}

/**
 * Handles clicking for mobile buttons
 *
 * @param card
 */
export function handleMobileButtons (card) {
  const buttonDiv = card.querySelector('[dk-target="mobile-buttons"]') as HTMLDivElement

  on('show-mobile-buttons', 'click', () => {
    buttonDiv.classList.add('u-mobileShow--flex')
  })(card)

  on('hide-mobile-buttons', 'click', () => {
    buttonDiv.classList.remove('u-mobileShow--flex')
  })(card)
}

/**
 * Handles the create your own bowl cards
 *
 * @param bowl
 * @param location
 * @param basket
 */
function setCreateYourOwnCard (bowl, location, basket) {
  const card = fromTemplate('cyo-bowl', bowl)!
  const links = card.querySelectorAll('[dk-target="customize-bowl"]')

  for (const link of Array.from(links)) {
    fromEvent(link, 'click').subscribe(async () => {
      await navigateToProduct(location, bowl.id)
    })
  }
  return card
}

/**
 * Sets the contents for a bowl card
 *
 * @param location
 * @param basket
 * @param bowl
 * @param data
 */
export function setBowlContents (location, basket, bowl, data): Element {
  if (bowl.name.match(/create your own/i)) return setCreateYourOwnCard(bowl, location, basket)

  const card = fromTemplate('bowl', bowl)!
  const image = getImageByGroupName(bowl.images, 'mobile-webapp-menu')

  setCardAttributesForBowl(card as HTMLDivElement, bowl, 'bowl', location, basket)
  handleMobileButtons(card)

  card.querySelector('[dk-target="product-image"]')!
    .setAttribute('src', data.imagepath + resizeOloImage(image.filename, 400, 300))

  return card
}


