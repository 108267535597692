import { ajax } from 'rxjs/ajax';

const baseURL = process.env.API_URL
const headers = {
    'Content-Type': 'application/json',
    'x-referer': window.location.href
  }

export interface LoginPayload {
  email: string
  password: string
}

export interface ResetPasswordPayload {
  email: string
}

export interface UpdateProductPayload {
  productid: string
  quantity: number
  options: string
  specialinstructions?: string
  recipient?: string
  customdata?: string
  choicecustomfields?: object[]
}

export interface ProductPayload {
  productid: number
  quantity: number
  options?: string
}

export interface DeliveryModePayload {
  deliverymode: string
}

export interface TimeWantedPayload {
  ismanualfire: boolean
  year: string
  month: string
  day: string
  hour: string
  minute: string
}

export interface OloLinkedUserPayload {
  provider: string
  providertoken: string
  provideruserid?: string
}

export interface AddFavoriteToBasketPayload {
  faveid: number
}

export interface CreateBasketFromFavoritePayload {
  faveid: number
  ignoreunavailableproducts: boolean
}

export interface CreateBasketFromOrderPayload {
  orderref: string
  id: string
  ignoreunavailableproducts: boolean
}

interface CreateFavoritePayload {
  description: string
  basketid: string
}

interface DeliveryAddressPayload {
  id?: number
  building?: string
  streetaddress?: string
  city?: string
  zipcode?: string
  phonenumber?: string
  specialinstructions?: string
}

interface DispatchAddressPayload {
  id?: number
  building?: string
  streetaddress: string
  city: string
  zipcode: string
  phonenumber: string
  specialinstructions?: string
  isDefault: boolean
}

interface TipPayload {
  amount: number
}

interface CustomFieldsPayload {
  id: number,
  value: string
}

interface CurbsideArrivalPayload {
  message: string
}

interface ApplyRewardsPayload {
  membershipid: number,
  references: string[]
}

export default {
  users: {
    login (data: LoginPayload) {
      return ajax.post(`${baseURL}/login`, { user: data }, headers)
    },
    getOloUser (data: OloLinkedUserPayload) {
      return ajax.post(`${baseURL}/users/get_or_create`, data, headers)
    },
    resetPassword (data: ResetPasswordPayload) {
      return ajax.post(`${baseURL}/users/forgot_password`, { user: data }, headers)
    },
    signUp (data) {
      return ajax.post(`${baseURL}/signup`, { user: data }, headers)
    },
    update (data) {
      return ajax.put(`${baseURL}/users`, data, headers)
    },
    changePassword (data) {
      return ajax.post(`${baseURL}/users/change_password`, data, headers)
    },
    balance (token) {
      return ajax.get(`${baseURL}/users/balance?authentication_token=${token}`, headers)
    },
    getBillingAccounts (token) {
      return ajax.get(`${baseURL}/users/billingaccounts?authentication_token=${token}`, headers)
    },
    getFaves (token) {
      return ajax.get(`${baseURL}/users/faves?authentication_token=${token}`)
    },
    getFaveLocations (token) {
      return ajax.get(`${baseURL}/users/favelocations?authentication_token=${token}`)
    },
    getRecentOrders (token) {
      return ajax.get(`${baseURL}/users/recentorders?authentication_token=${token}`)
    },
    getUserQualifyingRewards (authtoken: string, vendorid: number) {
      return ajax.get(`${baseURL}/users/${authtoken}/qualifyingrewards?vendorId=${vendorid}`, headers)
    },
    createFavorite (authtoken: string, payload: CreateFavoritePayload) {
      return ajax.post(`${baseURL}/users/${authtoken}/faves`, payload, headers)
    },
    deleteFavorite (authtoken: string, id: string) {
      return ajax.delete(`${baseURL}/users/${authtoken}/faves/${id}`, headers)
    },
    getDeliveryAddress (token: string) {
      return ajax.get(`${baseURL}/users/${token}/userdeliveryaddresses?authentication_token`)
    },
  },
  restaurants: {
    punchLocations () {
      return ajax.get(`${baseURL}/locations`, headers)
    },
    near (lat: number, long: number, radius = 25, limit = 5) {
      return ajax.get(`${baseURL}/restaurants/near?lat=${lat}&long=${long}&radius=${radius}&limit=${limit}`, headers)
    },
    menu (id = 15479) {
      return ajax.get(`${baseURL}/restaurants/${id}/menu`, headers)
    },
    calendars (id: number, fromDate: string, toDate: string) {
      return ajax.get(`${baseURL}/restaurants/${id}/calendars?from=${fromDate}&to=${toDate}`)
    },
    getByRef (id: number | string) {
      return ajax.get(`${baseURL}/restaurants/byref/${id}`, headers)
    },
    get (id: number | string) {
      return ajax.get(`${baseURL}/restaurants/${id}`, headers)
    }
  },
  baskets: {
    create (vendorid: number, accesstoken: string) {
      const payload = { vendorid }
      if (accesstoken) payload['accesstoken'] = accesstoken
      return ajax.post(`${baseURL}/baskets`, payload, headers)
    },
    getBasket (basketid: string) {
      return ajax.get(`${baseURL}/baskets/${basketid}`, headers)
    },
    addItemToBasket (basketid: string, product: ProductPayload) {
      return ajax.post(`${baseURL}/baskets/${basketid}/products`, product, headers)
    },
    removeItem(basketid: string, productid: string) {
      return ajax.delete(`${baseURL}/baskets/${basketid}/products/${productid}`, headers)
    },
    updateProduct(basketid: string, productid: string, payload: UpdateProductPayload) {
      return ajax.put(`${baseURL}/baskets/${basketid}/products/${productid}`, payload, headers)
    },
    setDeliveryMode(basketid: string, payload: DeliveryModePayload) {
      return ajax.put(`${baseURL}/baskets/${basketid}/deliverymode`, payload, headers)
    },
    setTimeModeToAsap(basketid: string) {
      return ajax.delete(`${baseURL}/baskets/${basketid}/timewanted`, headers)
    },
    setTimeWanted (basketid: string, payload: TimeWantedPayload) {
      return ajax.put(`${baseURL}/baskets/${basketid}/timewanted`, payload, headers)
    },
    addFavoriteToBasket(basketid: string, authtoken: string, payload: AddFavoriteToBasketPayload) {
      return ajax.post(`${baseURL}/baskets/${basketid}/products/byfave?authtoken=${authtoken}`, payload, headers)
    },
    createBasketFromFave (authtoken: string, payload: CreateBasketFromFavoritePayload) {
      return ajax.post(`${baseURL}/baskets/createfromfave?authtoken=${authtoken}`, payload, headers)
    },
    createBasketFromPreviousOrder (authtoken: string, payload: CreateBasketFromOrderPayload) {
      return ajax.post(`${baseURL}/baskets/createfromorder?authtoken=${authtoken}`, payload, headers)
    },
    validateBasket (id: string) {
      return ajax.post(`${baseURL}/baskets/${id}/validate`, {}, headers)
    },
    initCheckout (id: string, authtoken: string) {
      return ajax.post(`${baseURL}/baskets/${id}/checkout`, { authtoken }, headers)
    },
    transferBasketToDifferentRestaurant (basketId: string, vendorid: string) {
      return ajax.post(`${baseURL}/baskets/${basketId}/transfer`, { vendorid }, headers)
    },
    applyCouponToBasket (basketId, couponcode) {
      return ajax.put(`${baseURL}/baskets/${basketId}/coupon`, { couponcode }, headers)
    },
    removeCouponFromBasket (basketId) {
      return ajax.delete(`${baseURL}/baskets/${basketId}/coupon`, headers)
    },
    updateDeliveryAddress (basketId, payload: DeliveryAddressPayload) {
      return ajax.put(`${baseURL}/baskets/${basketId}/deliveryaddress`, payload, headers)
    },
    updateDispatchAddress (basketId, payload: DispatchAddressPayload) {
      return ajax.put(`${baseURL}/baskets/${basketId}/dispatchaddress`, payload, headers)
    },
    getBillingSchema (basketId) {
      return ajax.get(`${baseURL}/baskets/${basketId}/billingschemes`, headers)
    },
    updateTipAmount (basketId, payload: TipPayload) {
      return ajax.put(`${baseURL}/baskets/${basketId}/tip`, payload, headers)
    },
    updateCustomField (basketId, payload: CustomFieldsPayload) {
      return ajax.put(`${baseURL}/baskets/${basketId}/customfields`, payload, headers)
    },
    getBasketLoyaltyRewards (basketId: string, membershipid: any) {
      return ajax.get(`${baseURL}/baskets/${basketId}/loyaltyrewards?membershipid=${membershipid}`, headers)
    },
    getBasketQualifyingRewards (basketId: string, authtoken: string, membershipid: number) {
      return ajax.get(`${baseURL}/baskets/${basketId}/loyaltyrewards/qualifying?authtoken=${authtoken}&membershipid=${membershipid}`, headers)
    },
    applyRewardsToBasket (basketId: string, payload: ApplyRewardsPayload) {
      return ajax.put(`${baseURL}/baskets/${basketId}/loyaltyrewards/byref`, payload, headers)
    },
    removeAppliedReward (basketId: string, rewardId: string) {
      return ajax.delete(`${baseURL}/baskets/${basketId}/loyaltyrewards/${rewardId}`, headers)
    }
  },
  products: {
    modifiers (productId: string | number) {
      return ajax.get(`${baseURL}/products/${productId}/modifiers`, headers)
    }
  },
  orders: {
    arrivalNotice (orderGuid: string, payload: CurbsideArrivalPayload) {
      return ajax.post(`${baseURL}/orders/${orderGuid}/arrival`, payload, headers)
    }
  }
}
