import api from '../lib/api'
import type { Record } from '~dk/store'

type Props = {
  punchhLocations: Record<any>
}

export default (root: HTMLElement, { punchhLocations } : Props) => {
  /**
   * Fetches all punch locations
   * @returns
   */
  async function getPunchhLocations () {
    await api.restaurants.punchLocations().subscribe(({ response }) => {
      punchhLocations.next(response)
    })
  }

  /**
   * Sets the location options for a given text field
   * @param locationSelect
   */
  async function setLocationOptions(locationSelect: Element) {
    const locations = await punchhLocations.getValue()

    return locations.forEach((location: any) => {
      const option = document.createElement('option')
      option.value = location.location_id
      option.innerHTML = location.name
      locationSelect.append(option)
    })
  }

  /**
   * Sets the location options for a given text field (specifically, the contact form)
   * @param locationSelect
   */
   async function setContactLocationOptions(locationSelect: Element) {
    const locations = await punchhLocations.getValue()

    return locations.forEach((location: any) => {
      if (location.status == 'approved') {
        const option = document.createElement('option')
        option.value = location.name + ', ' + location.state + ' (ID: ' + location.store_number + ')'
        option.innerHTML = location.name + ', ' + location.state
        locationSelect.append(option)
      }
    })
  }

  /**
   * Finds the select input elements on a pages and sets their values based off of what has been returned from
   * the Punchh API
   */
  function setPunchLocationSelects (locations: any[]) {
      locations.sort((a, b) => a.name > b.name ? 1 : -1)
      const locationInputs = document.querySelectorAll('[dk-target="punchh-locations"]')

      locationInputs.forEach(setLocationOptions)
  }

  /**
   * Finds the select input elements on a pages and sets their values based off of what has been returned from
   * the Punchh API.
   * This function is specifically for the contact form locations select field.
   */
   function setPunchLocationContactSelects (locations: any[]) {
    locations.sort((a, b) => a.name > b.name ? 1 : -1)
    const locationInputs = document.querySelectorAll('[dk-target="punchh-contact-form-locations"]')

    locationInputs.forEach(setContactLocationOptions)
}

  punchhLocations.subscribe((locationData) => {
    if (!locationData) return getPunchhLocations()
    setPunchLocationSelects(locationData)
    setPunchLocationContactSelects(locationData)
  })

  return {
    start () {}
  }
}

