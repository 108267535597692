import type { Record } from '~dk/store'
import { forTarget, mount, on } from '~dk/core'
import { fromTemplate, setValues } from '~dk/content'
import { clearContents } from '~dk/helpers'
import moment from 'moment'
import api from '../lib/api'
import { clearAlert, showAlert } from '../lib/shared'
import { retry } from 'rxjs/operators'
import { BehaviorSubject, fromEvent } from 'rxjs'

interface Props {
  account: Record<any>
  basket: Record<any>
  orderTypeVerified: Record<any>
}

export default (root: HTMLElement, { account, basket, orderTypeVerified }: Props) => {

  let validatedBasket = new BehaviorSubject<Maybe<any>>(null)

  basket.subscribe((data) => {
    markAppliedRewards()
  })

  /**
   * Watch for changes to alert messages
   * and perform actions when necessary.
   */
  const alertMessageElement = forTarget<HTMLDivElement>(root, 'alert')!
  let alertMessage = '';
  const alertObserver = new MutationObserver(function (mutations) {
    mutations.every(function (mutation) {
      if (mutation.addedNodes.length) {
        alertMessage = alertMessageElement.innerHTML.trim();
        switch (true) {
          case (alertMessage == 'Your selected reward is not valid for this order.'):
            console.log('Try to remove the applied reward.')
            removeRewards()
            break
          case (alertMessage.endsWith('is currently closed. Order was not placed or billed.')):
            console.log('Try to remove the applied reward.')
            removeRewards()
            break
          case (alertMessage == 'Coupon code may not be applied since a loyalty reward and/or comp card is already applied to your basket. Remove any loyalty rewards and comp cards to proceed.'):
            const couponInput = <HTMLInputElement>document.querySelector('[dk-input="coupon-code"]')!
            couponInput.value = '';
            break
        }
      }
    })
  });
  alertObserver.observe(alertMessageElement, {
    subtree: true,
    childList: true,
    characterData: true,
    characterDataOldValue: false,
  });

  /**
   * Validate our basket
   */
  async function validateBasket () {
    const basketData = basket.getValue()
    api.baskets.validateBasket(basketData.id).subscribe(
      ({ response }) => validatedBasket.next(response),
      ({ response }) => handleValidationError(response),
      () => { console.log('Basket validated'), setTimeout( ()=> { refreshBasket() }, 1000) }
    )
  }

  /**
   * Refresh the basket after a reward is applied
   */
  async function refreshBasket () {
    const basketData = basket.getValue()
    api.baskets.getBasket(basketData.id).subscribe(
      ({ response }) => basket.next(response),
      ({ response }) => alert(response.message),
      () => console.log('Refreshed basket', basket)
    )
  }

  /**
   * Do stuff when our basket DOES NOT validate
   */
  async function handleValidationError(response) {
    alert(response.message)
    console.log('handleValidationError')
    removeRewards()
  }

  /**
   * Show user rewards at checkout
   */
  async function showUserRewards () {

    const basketData = await basket.getValue()
    const container = forTarget<HTMLElement>(root, 'reward-cards-container')!
    const membershipid = await getMembershipId()
    if (!membershipid) return

    const basketRewardsData = await getBasketRewards(membershipid)
    if (!basketRewardsData) return

    clearContents(container)
    await buildRewardsCards(container, basketRewardsData)

    fromEvent(container, 'click').subscribe((event) => {
      handleReward(event, basketData.id, membershipid)
    })
  }

  /**
   * Build the rewards cards.
   * Uses {{mustache}}
   * @param container 
   * @param basketRewardsData 
   */
  async function buildRewardsCards(container: HTMLElement, basketRewardsData: any) {
    for (const reward of basketRewardsData.rewards) {
      if (reward.label !== 'Banked Reward Dollars') {
        let hasExpires = reward.expirationdate || false
        let expireDate = moment(reward.expirationdate, 'YYYYMMDD HH:mm').format('MM/DD/YY')
        const template = fromTemplate('rewards-card',
          {
            ...reward, 
            label: reward.label, 
            fineprint: reward.fineprint, 
            quantityavailable: reward.quantityavailable, 
            membershipid: reward.membershipid, 
            rewardReference: reward.reference, 
            rewardId: reward.rewardid, 
            hasExpires: hasExpires, 
            expires: expireDate
          })!
        container.appendChild(template)
      }
    }
    markAppliedRewards()
  }

  /**
   * Mark/indicate applied reward(s)
   */
  async function markAppliedRewards () {
    const data = basket.getValue()
    let reference = null
    if (data.appliedrewards && data.appliedrewards[0]) {
      reference = data.appliedrewards[0].reference
    }
    const container = document.querySelector('[dk-target="reward-cards-container"]')!
    if (!container) return
    const cards = container.querySelectorAll('.a-button')
    cards.forEach((element) => {
      const applied = element.querySelector('.a-rewards__applied')
      element.classList.remove('active')
      applied?.setAttribute('style', 'display:none;')
      if (reference && (element.getAttribute('data-reward-reference') === reference)) {
        element.classList.add('active')
        applied?.setAttribute('style', 'display:block;')
      }
    })
    
  }

  /**
   * Apply or remove rewards from the basket
   */
  async function handleReward(event: any, basketId: string, membershipid: any) {

    const form = forTarget<HTMLElement>(root, 'checkout-form')!
    // const basketData = await basket.getValue()
    const clicked = <HTMLAnchorElement>event.target.closest('a')
    const reference: string = clicked.dataset.rewardReference!
    clearAlert(form)

    if (clicked.classList.contains('active')) {
      removeRewards()
    } else {
      await removeRewards()
      setTimeout(function() {
        applyRewards(basketId, membershipid, reference)
      }, 750)
    }
  }

  /**
   * Apply rewards
   * @param basketId
   * @param membershipId 
   * @param reference 
   */
  async function applyRewards(basketId, membershipId, reference) {
    const form = forTarget<HTMLElement>(root, 'checkout-form')!

    const payload = {
      membershipid: membershipId,
      references: [
        reference
      ]
    }

    api.baskets.applyRewardsToBasket(basketId, payload).subscribe(
      ({ response }) => basket.next(response),
      ({ response }) => showAlert(form, response.message),
      () => console.log('Reward applied')
    )

    validateBasket()
  }

  /**
   * Remove rewards
   * @param basketId 
   * @param rewardId 
   */
  async function removeRewards () {
    const basketData = await basket.getValue()
    const form = forTarget<HTMLElement>(root, 'checkout-form')!
    if (!basketData.appliedrewards[0]) return
    const rewardid = await basketData.appliedrewards[0].rewardid
    api.baskets.removeAppliedReward(basketData.id, rewardid).subscribe(
      ({ response }) => basket.next(response),
      ({ response }) => showAlert(form, response.message),
      () => console.log('Reward removed')
    )
  }

  /**
   * Get user rewards from Olo (which gets them from Punchh).
   * Can be used to retrieve `membershipid`
   * @returns Olo rewards object
   */
  async function getUserRewards () {
    const accountData = await account.getValue()
    const basketData = await basket.getValue()
    const { response } = await api.users.getUserQualifyingRewards(accountData.authtoken, basketData.vendorid).toPromise()
    return response
  }
  
  /**
   * Get basket rewards to show at checkout
   */
  async function getBasketRewards (membershipid: any) {
    const accountData = await account.getValue()
    const basketData = await basket.getValue()
    const { response } = await api.baskets.getBasketQualifyingRewards(basketData.id, accountData.authtoken, membershipid).toPromise()
    return response
  }

  /**
   * Get rewards that are applied to basket
   */
  async function getAppliedRewards () {
    const membershipid = await getMembershipId()
    const basketData = await basket.getValue()
    if (!membershipid) return
    const { response } = await api.baskets.getBasketLoyaltyRewards(basketData.id, membershipid).toPromise()
    return response
  }

  /**
   * Get membership ID
   */
  async function getMembershipId () {
    const userBasketRewardsData = await getUserRewards()
    const firstReward = await userBasketRewardsData.rewards[0]
    const membershipid = firstReward.membershipid
    return membershipid
  }

  return {
    start: mount(root,
      showUserRewards
    )
  }
}