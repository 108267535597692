import api from '../lib/api'
import { appendTo, fromTemplate, replaceContents } from '~dk/content'
import { forTarget } from '~dk/core'
import type { Record } from '~dk/store'
import { getInputValues } from '~dk/forms'
import { clearAlert, showAlert } from '../lib/shared'

type FavoriteType = 'favorite' | 'unfavorite'

/**
 * fetches the order history from the api
 */
export async function fetchOrderHistory (authtoken: string, previousOrders: Record<any>) {
  const { response } = await api.users.getRecentOrders(authtoken).toPromise()
  previousOrders.next(response)
}

/**
 * fetches favorites from the API
 */
export async function fetchFavorites (authtoken: string, faves: Record<any>) {
  const { response } = await api.users.getFaves(authtoken).toPromise()
  faves.next(response.faves)
}

/**
 * Adds the details for a product and it's respective choices to a card
 *
 * @param element
 * @param products
 */
function setProductDetailsForCard (element, products) {
  for (const product of products) {
    const productElement = fromTemplate('product-list', {
      productDescription: `${product.name} (x${product.quantity})`
    })!

    for (const choice of product.choices) {
      const li = document.createElement('li')
      li.innerHTML = choice.name
      appendTo(productElement, 'choices-list', li)
    }

    appendTo(element, 'products-container', productElement)
  }
}

/**
 * Displays the star and link icon for a give card
 *
 * @param element
 * @param starType
 */
export function getStar (element: Element, starType: FavoriteType): HTMLLinkElement {
  const star = forTarget<HTMLLinkElement>(element, starType)!
  star.style.display = 'block'
  return star
}

/**
 * Hides the star link and icon on a give card
 * @param element
 * @param starType
 */
export function hideStar (element: Element, starType: FavoriteType) {
  forTarget<HTMLLinkElement>(element, starType)!.style.display = 'none'
}


/**
 * Adds an order card to either the favorite order or last order column on the home page.
 *
 * @param root
 * @param targetContainer
 * @param order
 * @param favorite
 */
export function addOrderCardToHomepageFavorites (root: Element, targetContainer:string, order: any, favorite: boolean = false) {
  const element = fromTemplate('previous-order', order)!

  replaceContents(root, targetContainer, element)
  setProductDetailsForCard(element, order.products)
  return element
}

/**
 * Creates a basket from the previous order and sets that basket as a favorite.
 * If you try and pass in the id from the previous order, it'll give you an error that the basket couldn't be found.
 * I'm not sure if this was the original intent for the API, but this work around works.
 *
 * @param targetOrder
 * @param account
 * @param faves
 */
export function createFavoriteHandler (targetOrder: any, account: Record<any>, faves: Record<any>) {
  const accountData = account.getValue()

  return async function(event, _data, target) {
    event.preventDefault()
    const values = getInputValues(target) as { description: string }
    const { id, orderref } = targetOrder

    // create a temporary basket from the previous order
    const { response } = await api.baskets.createBasketFromPreviousOrder(accountData.authtoken, {
      orderref: orderref,
      id: id,
      ignoreunavailableproducts: true
    }).toPromise()

    // uses the new basket to create a favorite and close the modal
    api.users.createFavorite(accountData.authtoken, { ...values, basketid: response.id })
      .subscribe(
        () => {
          clearAlert(target)
          fetchFavorites(account.getValue()?.authtoken, faves)
          target.querySelector('[dk-input="description"]')!.value = ''
          target.querySelector('[dk-action="close-new-favorite-modal"]')!.click()
        },
        ({ response }) => showAlert(target, response.message, 'error'))
  }
}

/**
 * Creates a basket from your previous order
 *
 * @param basket
 * @param account
 * @param order
 */
export function createBasketFromPreviousOrder (basket: Record<any>, account: Record<any>, order: any) {
  const basketData = basket.getValue()
  const { authtoken } = account.getValue()

  if (!basketData || confirm('This will overwrite your current order. Do you want to continue?')) {
    api.baskets.createBasketFromPreviousOrder(authtoken, {
      orderref: order.orderref,
      id: order.id,
      ignoreunavailableproducts: true,
    })
      .subscribe(
        ({ response }) => basket.next(response),
        (error) => console.error(error),
      )
  }
}

/**
 * Adds a favorite to the basket. If the user doesn't have a basket it'll create one
 *
 * @param basket
 * @param account
 * @param order
 */
export function addFavoriteToBasket (basket: Record<any>, account: Record<any>, order: any) {
  const basketData = basket.getValue()
  const { authtoken } = account.getValue()

  if (basketData) {
    api.baskets.addFavoriteToBasket(basketData.id, authtoken, { faveid: order.id })
      .subscribe(
        ({ response }) => basket.next(response.basket),
        (error) => console.error(error),
      )
  } else {
    api.baskets.createBasketFromFave(authtoken, { faveid: order.id, ignoreunavailableproducts: true })
      .subscribe(
        ({ response }) => basket.next(response),
        (error) => console.error(error),
      )
  }
}

