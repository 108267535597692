import type { Record } from '~dk/store'
import { mount } from '~dk/core'

type Props = {
  basket: Record<any>
}

export default function (root: HTMLElement, { }: Props) {
  return {
    start: mount(root,
      () => {
        // const basketData = basket.getValue() as any
        // api.baskets.getBillingSchema(basketData.id).subscribe(
        //   ({ response }) => console.log(response)
        // )
      }
    )
  }
}
