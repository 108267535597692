import { forTarget, mount, on } from '~dk/core'
import { convertFormInputToObject, getInputValues } from '~dk/forms'
import api from '../lib/api'
import type { LoginPayload } from '../lib/api'
import type { Record } from '~dk/store'
import { hideAlert, showAlert } from '../lib/shared'

interface Props {
  user: Record<any>
  account: Record<any>
  faveLocations: Record<any>
}

export default (root: HTMLElement, { user, account, faveLocations }: Props) => {
  user.subscribe((data) => {
    if (!data) return
    setProfileLinkText(data)
    toggleUserOnlyContent()
    handleUserNotice()

    // Close the modal
    const closeButton = forTarget<HTMLLinkElement>(root, 'close-modal')
    closeButton?.click()
  })

  /**
   * makes a request to reset the users password
   *
   * @param e
   */
  function requestPasswordReset (e: Event) {
    e.preventDefault()

    const target = e.target as HTMLFormElement
    const data = convertFormInputToObject(target)

    api.users.resetPassword({ email: data['account-email'] })
      .subscribe(({ status}) => {
        if (status === 200) {
          $(target).children('input').attr('disabled', 'disabled')
          $(target).children('input[type=submit]').addClass('disabled').val('Email sent...')
          $(target).siblings('.success-message').fadeIn()
        }
      })
  }

  /**
   * Fetch the Olo auth token and update the account record
   *
   * @param userData
   */
  async function getUserInfoFromOlo (userData) {
    const { response } = await api.users.getOloUser({
      provider: 'punchh',
      providertoken: userData.access_token,
    }).toPromise()

    account.next(response)
    await getFavLocations(response)
  }

  async function getFavLocations (userData) {
    if (!userData) return console.error("Couldn't fetch user token from Olo Api")
    const { response } = await api.users.getFaveLocations(userData.authtoken).toPromise()
    faveLocations.next(response.favelocations)
  }

  /**
   * Handles user sign up and redirects them to the menu
   *
   * @param e
   */
  async function handleSignUp (e: Event) {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const data = getInputValues(form) as any

    if (data.password !== data.password_confirmation) {
      showAlert(form, "Passwords don't match", 'warning')
    }

    api.users.signUp(data).subscribe(
      ({ response }) => {
        user.next(response)
        window.location.pathname = '/menu'
      },
      ({ response }) => {
        const errors = response.errors
        const message = Object.keys(errors).reduce((acc, error) => {
          return acc + `<li><strong>${error}</strong> ${errors[error]}</li>`
        }, '')

        showAlert(form, `<ul>${message}</ul>`, 'warning')
      }
    )
  }

  /**
   * Hides or shows user specific content based on their auth status
   */
  function toggleUserOnlyContent () {
    const userElements = $('[dk-user="user"]')
    const guestElements = $('[dk-user="!user"]')

    if (user.getValue()) {
      userElements.show()
      guestElements.hide()
    } else {
      userElements.hide()
      guestElements.show()
    }
  }

  /**
   * Sets the profile link test
   */
  function setProfileLinkText (data) {
    const link = forTarget<HTMLElement>(document, 'profile-link')!
    link.innerHTML = `Hi, ${data.first_name}`
  }

  /**
   * Handles authentication for the user sign-in form
   *
   * @param e submit event
   */
  async function handleSignIn (e: Event) {
    e.preventDefault()
    const payload = convertFormInputToObject(e.target as HTMLFormElement)

    api.users.login(payload as LoginPayload).subscribe(
      ({ response }) => {
        user.next(response)
        getUserInfoFromOlo(response)
      },
      ({status}) => {
        if (status === 401)
          showAlert(root, 'Check your email address or password and try again', 'warning')
      }
    )
  }

  function subscribeToUser () {
    user.subscribe(async (data) => {
      const accountData = account.getValue()
      if (data && !accountData) return await getUserInfoFromOlo(data)
    })
  }

  /**
   * Handle user notice in footer.
   * This was set up to handle user account notices.
   */
  function handleUserNotice () {
    const userNoticeBtn = document.querySelector('[dk-action="hide-user-notice"]')
    const accountUpdateNotice = document.getElementById('accountUpdateNotice')
    if (!userNoticeBtn || !accountUpdateNotice) return
    if (checkUserNoticeCookie()) {
      accountUpdateNotice.style.display = 'none'
      return
    }
    accountUpdateNotice.style.display = 'flex'
    userNoticeBtn.addEventListener('click', function (e: Event) {
      e.preventDefault()
      setUserNoticeCookie()
      accountUpdateNotice.style.display = 'none'
    })
  }

  /**
   * Check for siteUserNotice cookie
   * @returns bool
   */
  function checkUserNoticeCookie (): boolean {
    if (document.cookie.split('; ').find((row) => row.startsWith('siteUserNotice'))) {
      return true
    } else {
      return false
    }
  }

  /**
   * Set cookie so that user notices stay hidden
   */
  function setUserNoticeCookie () {
    if (!document.cookie.split('; ').find((row) => row.startsWith('siteUserNotice'))) {
      document.cookie = "siteUserNotice=true; expires=Wed, 31 Aug 2022 23:59:59 GMT; SameSite=None; Secure";
    }
  }

  return {
    start: mount(root,
      subscribeToUser,
      on('sign-in', 'submit', handleSignIn),
      on('sign-in', 'input', () => hideAlert(root)),
      on('request-password-reset', 'submit', requestPasswordReset),
      on('sign-up', 'submit', handleSignUp),
      on('sign-up', 'input', () => hideAlert(root)),
    )
  }
}


