import { forTarget, mount, on } from '~dk/core'
import api from '../lib/api'
import { fromTemplate, prependTo } from '~dk/content'
import moment from 'moment'
import { toUSD } from '~dk/formatters'
import { RootElement } from '~dk/watch'

import {
  fetchOrderHistory,
  fetchFavorites,
  createFavoriteHandler,
  createBasketFromPreviousOrder, addFavoriteToBasket,
} from '../utils/favorites'

import type { Record } from '~dk/store'
import { fromEvent, Subject } from 'rxjs'

interface Props {
  account: Record<any>
  previousOrders: Record<any>
  faves: Record<any>
  basket: Record<any>
}

export default function (root: HTMLElement, { account, previousOrders, faves, basket}: Props) {
  const accountData = account.getValue()
  const modal = forTarget<HTMLDivElement>(root, 'new-favorite-modal')!
  const getOrderHistory = () => fetchOrderHistory(accountData.authtoken, previousOrders)
  const getFavorites = () => fetchFavorites(accountData.authtoken, faves)
  const targetOrder$ = new Subject()
  let handleCreatingFavorite: Function

  // Depending on the order you click on, the handle creating favorite function needs to be updated
  targetOrder$.subscribe((order) => {
    handleCreatingFavorite = createFavoriteHandler(order, account, faves)
  })

  /**
   * Swaps the active tab and it's contents
   *
   * @param event
   * @param _
   * @param target
   */
  function showTab (event, _, target) {
    event.preventDefault()

    for (const child of target.parentElement.children) {
      child.classList.remove('active')
    }

    target.classList.add('active')
  }

  /**
   * Handles the open/close of order details
   *
   * @param element
   */
  function handleShowOrderDetails (element: Element) {
    on('show-details', 'click', (_event, _data, target) => {
      const isOpen = target.getAttribute('closed') === 'true'
      const detailsElement = forTarget<HTMLDivElement>(element as RootElement, 'order-details')!

      target.setAttribute('closed', String(!isOpen))

      if (isOpen) {
        target.innerText = '- Hide Order Details'
        detailsElement.style.height = detailsElement.scrollHeight + 'px'
      } else {
        target.innerText = '+ Show Order Details'
        detailsElement.style.height = '0px'
      }
    })(element as HTMLElement)
  }

  /**
   * Sets the details for a product based on it's options
   *
   * @param element
   * @param order
   */
  function setProductDetails (element: Element, order: any) {
    const { products } = order
    const container = forTarget<HTMLDivElement>(element as RootElement,'order-products')

    for (const product of products) {
      const choices = product.choices
        ?.reduce((acc, choice) => {
          const quantity = choice.quantity > 1 ? choice.quantity + 'x ' : ''
          return acc + `<li>- ${quantity} ${choice.name}</li>`
        }, '')

      const li = `
        <li>
          <strong>${product.name}</strong>
          <ul style='list-style-type: none; padding-left: 10px'>${choices}</ul>
        </li>
      `
      container?.insertAdjacentHTML('beforeend', li)
    }
  }

  /**
   * Reorder favorite or previous order
   *
   * @param element
   * @param order
   */
  function handleReorder (element: Element, order: any) {
    on('reorder-favorite-order', 'click', () => {
      addFavoriteToBasket(basket, account, order)
    })(element as RootElement)

    on('reorder-previous-order', 'click', () => {
      createBasketFromPreviousOrder(basket, account, order)
    })(element as RootElement)
  }

  /**
   * Handles favoriting a previous order
   *
   * @param element
   * @param order
   */
  function handleFavoriting (element: Element, order) {
    on('favorite-previous-order', 'click', () => {
      modal.style.display = 'flex'
      modal.querySelector('input')!.focus()
      targetOrder$.next(order)
    })(element as RootElement)
  }

  /**
   * Handles deleting a favorite
   *
   * @param element
   * @param order
   */
  function handleDeletingFavorite (element: Element, order) {
    on('unfavorite', 'click', () => {
      if (!confirm('Are you sure you want to remove this favorite?')) return
      api.users.deleteFavorite(accountData.authtoken, order.id).subscribe(() => getFavorites())
    })(element as RootElement)
  }

  /**
   * Adds the previous order of favorite card to their respective container
   *
   * @param template
   * @param targetContainer
   * @param order order record
   * @param record data to display on the card
   */
  function addRecordCards (template: string, targetContainer: string, order, record: any) {
    const element = fromTemplate(template, record)!
    setProductDetails(element, order)
    handleShowOrderDetails(element)
    handleReorder(element, order)
    handleFavoriting(element, order)
    handleDeletingFavorite(element, order)

    prependTo(root, targetContainer, element)
  }

  /**
   * For each order in the order history, create a card and add it to the page
   *
   * @param data previous order data
   */
  async function setPreviousOrderContents (data: { orders: any[] }) {
    forTarget<HTMLDivElement>(root, 'previous-orders-container')!.innerHTML = ''

    // Get the restaurants street address
    const getRestaurantAddress = async (id) => {
      const { response } = await api.restaurants.get(id).toPromise()
      return response.streetaddress
    }

    // loop through each order and generate a card element
    for await (const [index, order] of data.orders.reverse().entries()) {
      addRecordCards('previous-order', 'previous-orders-container', order, {
        order_name: `Order #${index + 1}`,
        order_date: moment(order.timeplaced, 'YYYYMMDD HH:mm').format('MM/DD/YY [at] hh:mma'),
        order_total: toUSD(order.total),
        location_name: order.vendorname,
        order_reference: order.orderref,
        address: await getRestaurantAddress(order.vendorid)
      })
    }
  }

  /**
   * Sets the favorite contents
   *
   * @param data
   */
  function setFavoriteOrderContents (data: any[]) {
    forTarget<HTMLDivElement>(root, 'favorite-orders-container')!.innerHTML = ''

    // loop through each order and generate a card element
    for (const order of data) {
      addRecordCards('favorite-order', 'favorite-orders-container', order, {
        order_name: order.name
      })
    }
  }

  /**
   * subscribe to changes to the previous orders record
   */
  function subscribeToPreviousOrders () {
    previousOrders.subscribe(async (data) => {
      if (!data) return await getOrderHistory()
      await setPreviousOrderContents(data)
    })
  }

  /**
   * Checks if the user has favorites, if not display a placeholder
   *
   * @param faves
   */
  function toggleNoFavoritesBanner (faves: any[]) {
    const noFavoritesDiv = forTarget<HTMLDivElement>(root, 'no-favorites')!
    noFavoritesDiv.style.display = faves.length ? 'none' : 'block'
  }

  /**
   * subscribe to changes to the favorite orders record
   */
  function subscribeToFavoriteOrders () {
    faves.subscribe(async (data: any) => {
      if (!data) return await getFavorites()
      await setFavoriteOrderContents(data)
      toggleNoFavoritesBanner(data)
      modal.style.display = 'none'
    })
  }

  /**
   * Handles form submission when creating a favorite
   */
  function handleFavoriteFormSubmission () {
    const target = document.querySelector<HTMLFormElement>('[dk-action="create-favorite"]')!

    fromEvent(target, 'submit').subscribe((event) => {
      handleCreatingFavorite(event, null, target)
    })
  }

  return {
    start: mount(root,
      on('all-orders', 'click', showTab),
      on('favorites', 'click', showTab),
      handleFavoriteFormSubmission,
      subscribeToPreviousOrders,
      subscribeToFavoriteOrders
    )
  }
}
