export class NoLocationError extends Error {
  constructor (message) {
    super(message)
    this.name = 'NoLocationError'
  }
}

export class ProductNotFoundError extends Error {
  constructor (message) {
    super(message)
    this.name = 'ProductNotFound'
  }
}
