import { forTarget, mount } from '~dk/core'
import type { Record } from '~dk/store'
import api from '../lib/api'

type Props = {
  user: Record<any>
}

export default function (root: HTMLElement, { user }: Props) {
  /**
   * Sets the rewards balance value
   *
   * @param banked_currency
   */
  function updateCRewardsBalance ({ banked_currency }) {
    const element = forTarget<HTMLDivElement>(root, 'balance')!
    element.innerHTML = `Rewards balance: $${banked_currency}`
  }

  /**
   * Sets the unbanked points value
   *
   * @param unbanked_points
   */
  function updatePointValues ({ unbanked_points }) {
    const element = forTarget<HTMLDivElement>(root, 'points')!
    element.innerHTML = `You're ${750 - unbanked_points} points away from earning your next $10 in rewards!`
  }

  /**
   * Updates the rewards page to show current values
   */
  async function setBalanceValues () {
    const { authentication_token } = user.getValue()
    const { response } = await api.users.balance(authentication_token).toPromise()

    updateCRewardsBalance(response.account_balance)
    updatePointValues(response.account_balance)
  }

  return {
    start: mount(root, setBalanceValues)
  }
}
