import Mustache from 'mustache'
import { RootElement } from './watch'
import type { Record } from '~dk/store'

type Content = Element[] | Element | string

/**
 * Fetches an element with the specified dk-target value
 *
 * @param root
 * @param target
 * @returns
 */
function acquireTarget (root: Element, target: string) {
  return root.querySelector(`[dk-target="${target}"]`)
}

/**
 * Creates an element from a template
 *
 * @param templateName
 * @param record
 * @returns
 */
export function fromTemplate (templateName: string, record: any) {
  const element = document.querySelector(`[dk-template="${templateName}"]`)!
  if (!element) return

  const result =
    [element]
      .map((elem: Element) => elem.outerHTML)
      .map((template) => Mustache.render(template, record))
      .map((template) => template.replace(`dk-template="${templateName}"`, ''))
      .map((template) => document.createRange().createContextualFragment(template))
      .map((frag) => frag.children[0])

  return result?.[0]
}

/**
 * Appends an element to a parent container element
 *
 * @param root
 * @param target
 * @param content
 * @returns
 */
export function appendTo (root: Element, target: string, content: Content) {
  const container = acquireTarget(root, target)
  if (!container || !content) return
  if (Array.isArray(content)) {
    content.forEach(element => container.append(element))
  } else {
    container.append(content)
  }
}

/**
 * Prepends an element to a parent container element
 *
 * @param root
 * @param target
 * @param content
 * @returns
 */
export function prependTo (root: Element, target: string, content: Element | string) {
  const container = acquireTarget(root, target)
  if (!container || !content) return

  container.prepend(content)
}

export function replaceContents (root: Element, target: string, content: Content) {
  const container = acquireTarget(root, target)
  if (!container || !content) return
  container.innerHTML = ''
  appendTo(root, target, content)
}

/**
 * Sets the contents of an element
 *
 * @param root
 * @param target
 * @param content
 * @returns
 */
export function setContents (root: Element, target: string, content?: Element | string) {
  const container = acquireTarget(root, target)
  if (!container || !content) return

  typeof content === 'string' ? (container.innerHTML = content) : (container.innerHTML = content.outerHTML)
}

/**
 *
 * @param root
 * @param target
 * @param content
 */
export function diddleContents (root: Element, target: string, ...content: Element[] | string[]) {
  const container = acquireTarget(root, target)!
  if (!container) return console.warn(`Couldn't find target element "${target}"`)
  container.innerHTML = '' // Clear the containers contents
  content.forEach((c) => container.append(c))
}

/**
 * Sets the value of an element
 *
 * @param root
 * @param subject
 * @param template
 * @returns
 */
export function setValues (root: Maybe<Element>, subject: Record<any>, template?: string) {
  if (!root || !subject) return
  const elems = root.querySelectorAll('[dk-value]')
  const record = subject.getValue()!

  Array.from(elems).forEach((elem) => {
    const key = elem.getAttribute('dk-value')
    if (!key) return
    const value = record[key || '']

    if (template) {
      elem.innerHTML = Mustache.render(template, { value })
    } else {
      elem.innerHTML = value || []
    }
  })
}

/**
 * Sets the value for a given element
 *
 * @param rootElement
 * @param target
 * @param value
 */
export function setValue (rootElement: RootElement, target:string, value: any) {
  rootElement.querySelector(`[dk-value="${target}"]`)!.innerHTML = value
}

