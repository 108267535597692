import type { Record } from '~dk/store'
import { forTarget, mount, trigger } from '~dk/core'
import { fromTemplate, setValues } from '~dk/content'
import { setFormat, toUSD } from '~dk/formatters'
import { convertFormInputToObject, maskInputs, setInputValues } from '~dk/forms'
import { on } from '~dk/core'
import api from '../lib/api'
import { BehaviorSubject } from 'rxjs'
import { retry } from 'rxjs/operators'
import { showAlert } from '../lib/shared'

interface Props {
  account: Record<any>
  basket: Record<any>
  checkoutUser: Record<any>
  user: Record<any>
  location: Record<any>
  recentOrder: Record<any>
  paymentMethods: Record<any>
  orderTypeVerified: Record<boolean>
}

const css = `
  border-color: #CCC;
  border-bottom-style: solid;
  border-top-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  border-width: 1px;
  color: #333333;
  font-family: Asap, sans-serif;
  font-size: 14px;
  height: 36px;
  padding: 0 10px;
  width: calc(100% - 22px);
  `

export default (root: HTMLElement, { account, user, location, basket, checkoutUser, recentOrder, paymentMethods, orderTypeVerified }: Props) => {
  let checkoutFrame: any
  let accessToken: string
  let validatedBasket = new BehaviorSubject<Maybe<any>>(null)

  function alert(message) {
    const form = root.querySelector('[dk-target="checkout-form"]')! as HTMLFormElement
    showAlert(form, message, 'error')
  }

  function validateBasket () {
    const basketData = basket.getValue()

    api.baskets.validateBasket(basketData.id).pipe(retry(3)).subscribe(
      ({ response }) => validatedBasket.next(response),
      ({ response }) => alert(response.message),
      // () => console.log('validatedBasket', validatedBasket)
    )
  }

  /**
   * Sets the location data on the checkout page
   */
  function setLocationData () {
    const restaurant = location.getValue()
    const target = forTarget<HTMLDivElement>(root, 'pickup-location')!

    target['innerHTML'] = `
      <strong>${restaurant.name}</strong><br>
      ${restaurant.streetaddress}<br>
      ${restaurant.city}, ${restaurant.state}, ${restaurant.zip}
    `
  }

  /**
   * Updates the current user block on the checkout page
   */
  function setUserData() {
    const user = checkoutUser.getValue()
    const target = forTarget<HTMLDivElement>(root, 'user-details')!

    if (!target) return

    if (user) {
      target['innerHTML'] = `
        ${user.first_name} ${user.last_name}<br>
        ${user.email}<br>
        ${user.phone}
      `
    } else {
      const editUserLink = root.querySelector('[dk-action="edit-checkout-user"]')! as HTMLLinkElement
      editUserLink.click()
    }
  }

  /**
   * Adds Olo's environment specific script tag to the page
  */
  function addScriptTagToPage () {
    const tag = document.createElement('script')
    const jsUrl = process.env.CC_FRAME_JS_URL!
    tag.setAttribute('src', jsUrl)

    const head = document.querySelector('head')!
    head.appendChild(tag)
  }

  /**
   * Initializes the olo checkout inputs based on their docs
   */
  async function initOlo () {

    await addScriptTagToPage()

    const basketData = basket.getValue()
    const accountData = account.getValue()
    if (!basketData) return

    api.baskets.initCheckout(basketData.id, accountData?.authtoken).pipe(retry(3)).subscribe(( { response }) => {
      const brandAccessId = process.env.OLO_BRAND_ACCESS_ID
      accessToken = response.accesstoken
      // @ts-ignore
      checkoutFrame = new Olo.CheckoutFrame({
        cardElement: 'olo-card-element',
        cvvElement: 'olo-cvv-element'
      })

      checkoutFrame.initialize({
        brandAccessId,
        styles: {
          'cardNumber': css,
          'cvv': css
        }
      })

      checkoutFrame.on('ready', displayBillingMethods())

      checkoutFrame.on('success', (response) => {
        recentOrder.next(response)
        basket.next(null) // Clear the basket
        window.location.pathname = '/thanks'
      })
    })
  }

  /**
   * Show/hide billing method buttons
   */
  function displayBillingMethods(): void {
    const loadingPaymentMethods = forTarget<HTMLElement>(root, 'loading-payment-methods')!
    loadingPaymentMethods.style.display = 'none'
  }

  /**
   * Pops the Order Type Modal and flags that the user has verified their order type
   */
  function displayOrderTypeModal () {
    const editButton = forTarget<HTMLLinkElement>(root, 'edit-order-type')!
    editButton.click()
  }

  /**
   * Builds the order payload and submits it to Olo
   */
  function submitOrder (event) {
    event.preventDefault()

    if (!orderTypeVerified.getValue()) {
      displayOrderTypeModal()
      return orderTypeVerified.next(true)
    }

    const basketData = basket.getValue()
    const checkoutUserData = checkoutUser.getValue()
    const accountData = account.getValue()
    const billingAccounts = paymentMethods.getValue() || []
    const validatedBasketData = validatedBasket.getValue()

    // If there is only one payment, apply the entire amount of the order
    if (billingAccounts.length === 1) billingAccounts[0].amount = validatedBasketData.total

    checkoutFrame.submit({
      id: basketData.id,
      accessToken,
      firstName: checkoutUserData.first_name,
      lastName: checkoutUserData.last_name,
      emailAddress: checkoutUserData.email,
      userType: accountData ? 'user' : 'guest',
      contactNumber: checkoutUserData.phone,
      guestOptIn: false,
      billingAccounts
    })

    checkoutFrame.on('error', (error: any[]) => {
      const form = root.querySelector('[dk-target="checkout-form"]')! as HTMLFormElement
      let errorMessage = error[0]?.description
      
      // show a more useful error when there's no payment method on dispatch orders
      if (!billingAccounts.length && errorMessage == 'The sum of your selected payment method tips must equal the order tip.') {
        errorMessage = 'Please add a payment method.'
      }

      // show a more useful error when there's no payment method on pickup or curbside orders
      if (!billingAccounts.length && errorMessage == 'The sum of your selected payment methods must equal the order total.') {
        errorMessage = 'Please add a payment method.'
      }

      showAlert(form, errorMessage, 'error')
    })
  }

  /**
   * If the user is signed in, mirror that data to the current user
   */
  function setCheckoutUserData(user) {
    const checkoutUserData = checkoutUser.getValue()
    if (!checkoutUserData && user) checkoutUser.next(user)
  }

  function hideLogInButtonForUserDetails (user: Maybe<any>) {
    try {
      forTarget<HTMLDivElement>(root, 'login-or-continue')!.style.display = user ? 'none' : 'block'
      forTarget<HTMLDivElement>(root, 'user-details-form')!.style.display = user ? 'block' : 'none'
    } catch (error) {
      console.error(error)
    }
  }

  function subscribeToUser() {
    user.subscribe((user) => {
      setCheckoutUserData(user)
      hideLogInButtonForUserDetails(user)
    })
  }

  /**
   * Updates the checkoutUser record based on the form input
   *
   * @param _e unused
   * @param _value unused
   * @param form
   */
  function updateCheckoutUser (_e, _value, form) {
    const data = convertFormInputToObject(form)
    checkoutUser.next(data)
  }

  /**
   * Closes the checkout details modal
   *
   * @param e
   */
  function closeCheckoutModal (e: Event) {
    e.preventDefault()
    const closeButton = root.querySelector('[dk-target="close-checkout-modal"]')! as HTMLLinkElement
    closeButton.click()
  }

  /**
   * Cycles through each input of the checkout user modal and sets their values
   */
  function updateUserInput() {
    const user = checkoutUser.getValue()
    if (!user) return
    const userForm = root.querySelector('[dk-action="checkout-update-user"]')!
    setInputValues(userForm, user)
  }

  /**
   * Displays items in the order summary section of the checkout page
   */
  function showOrderSummaryContent () {
    basket.subscribe((data) => {
      const container = document.querySelector('[dk-target="product-list"]') as HTMLUListElement

      if (container && data !== null) {
        const { products, tip } = data as any

        // Set the tip amount
        forTarget<HTMLSpanElement>(root, 'validated-tip')!.innerText = toUSD(tip)

        // Remove existing elements
        const existingElements = container.querySelectorAll('li:not([dk-template])')
        existingElements.forEach((element) => element.remove())

        // Add each product to the order summary section
        products.forEach(product => {
          if (product.recipient) product.name = `${product.name} (${product.recipient})`

          const template = fromTemplate('checkout-product', product) as HTMLElement

          // Update the total cost to display as usd
          template
            .querySelector('[dk-target="cash-money"]')!
            .setAttribute('dk-format', 'us-dollars')

          setFormat(template)

          // Append the new element to the container
          container.append(template)
        })
      }
    })
  }

  /**
   * The Credit Card payment method is relies on the form being complete.
   * So, when the user refreshes the page, remove it
   */
  function removeCreditCardOnReload () {
    const current = paymentMethods.getValue() as any[]
    const updated = current?.filter((method) => method.billingMethod !== 'creditcard')
    paymentMethods.next(updated)
  }

  /**
   *
   */
  function promptTheUserForOrderType ()  {
    if (orderTypeVerified.getValue()) return
    const target = forTarget<HTMLLinkElement>(root, 'edit-order-type')!
    target.click()
  }

  /**
   * It's possible to navigate to this page without having a basket. If so redirect the user to the menu page
   */
  function redirectIfNoBasket () {
    setTimeout(() => {
      if (!basket.getValue()) window.location.pathname = '/menu'
    }, 100)
  }

  /**
   * Set delivery fee amount in list
   */
  function setDeliveryValuesInSubtotal () {
    const basketData = basket.getValue()
    const containingDiv = forTarget<HTMLDivElement>(root, 'delivery-fee-content')!

    if (basketData.deliverymode === 'dispatch') {
      containingDiv.style.display = 'flex'
      forTarget<HTMLSpanElement>(root, 'delivery-fee')!.innerHTML = toUSD(basketData.customerhandoffcharge)
    } else {
      containingDiv.style.display = 'none'
    }
  }

  /**
   * Set tip and service fee if order is a delivery
   */
  function setDispatchTipAndFeesInSubtotal () {
    const basketData = basket.getValue()
    const serviceFeeDiv = forTarget<HTMLDivElement>(root, 'service-fee-content')!
    const tipDiv = forTarget<HTMLDivElement>(root, 'validated-tip-content')!

    if (basketData.deliverymode === 'dispatch' && basketData.fees[0]) {
      serviceFeeDiv.style.display = 'flex'
      forTarget<HTMLSpanElement>(root, 'service-fee')!.innerHTML = toUSD(basketData.fees[0].amount)
    } else {
      serviceFeeDiv.style.display = 'none'
    }
    
    if (basketData.deliverymode === 'dispatch') {
      tipDiv.style.display = 'flex'
      // tip display happens in OrderType.ts
    } else {
      tipDiv.style.display = 'none'
    }
  }

   /**
   * Set reward
   */
    function setRewardsInSubtotal () {
      const basketData = basket.getValue()
      const appliedRewardDiv = forTarget<HTMLDivElement>(root, 'applied-reward')!
      const reward = forTarget<HTMLDivElement>(root, 'reward-title')!
  
      if (basketData.appliedrewards[0]) {
        appliedRewardDiv.style.display = 'block'
        reward.style.display = 'block'
        forTarget<HTMLSpanElement>(root, 'reward-title')!.innerHTML = basketData.appliedrewards[0].label
      } else {
        appliedRewardDiv.style.display = 'none'
        reward.style.display = 'none'
      }
    }

  // Subscriptions
  function subscribeToValidatedBasket () {
    validatedBasket.subscribe((validation) => {
      if (!validation) return
      const { total, subtotal, tax } = validation as any

      setDeliveryValuesInSubtotal()
      setDispatchTipAndFeesInSubtotal()
      setRewardsInSubtotal()
      forTarget<HTMLSpanElement>(root, 'validated-total')!.innerHTML = toUSD(total)
      forTarget<HTMLSpanElement>(root, 'validated-subtotal')!.innerHTML = toUSD(subtotal)
      forTarget<HTMLSpanElement>(root, 'validated-tax')!.innerHTML = toUSD(tax)
    })
  }

  function subscribeToCurrentUser () {
    checkoutUser.subscribe(() => {
      setUserData()
      updateUserInput()
    })
  }

  function subscribeToBasket () {
    basket.subscribe(data => {
      if (!data) return

      setValues(root, basket)
      setFormat(root)
      setLocationData()
      setUserData()
      if (orderTypeVerified.getValue()) validateBasket()
    })
  }

  function subscribeToOrderTypeVerification () {
    orderTypeVerified.subscribe((data) => {
      if (data && !validatedBasket.getValue()) validateBasket()
    })
  }

  return {
    start: mount(root,
      trigger('checkout-form', () => {
        // addScriptTagToPage()
        initOlo()
      }),
      on('checkout-update-user', 'input', updateCheckoutUser),
      on('checkout-update-user', 'submit', closeCheckoutModal),
      on('validate-and-checkout', 'submit', submitOrder),
      subscribeToCurrentUser,
      subscribeToUser,
      subscribeToBasket,
      subscribeToValidatedBasket,
      subscribeToOrderTypeVerification,
      showOrderSummaryContent,
      removeCreditCardOnReload,
      promptTheUserForOrderType,
      maskInputs,
      redirectIfNoBasket,
    )
  }
}
