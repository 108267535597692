import { mount } from '~dk/core'
import api from '../lib/api'
import type { Record } from '~dk/store'

interface Props {
  account: Record<any>
  billingAccounts: Record<any>
  paymentMethods: Record<any>
}

interface BillingAccounts {
  billingaccounts: any[]
}

export default function (root: HTMLElement, { account, billingAccounts, paymentMethods }: Props) {
  async function fetchUserAccounts () {
    const accountData = account.getValue()
    const token = accountData?.authtoken
    if (!token) return

    if (!billingAccounts.getValue()) {
      const { response } = await api.users.getBillingAccounts(token).toPromise()
      billingAccounts.next(response)
    }
  }

  function subscribeToBillingAccounts () {
    billingAccounts.subscribe((accounts: BillingAccounts) => {
      const description = accounts?.billingaccounts?.[0]?.description
      if (!description) return

      if (!paymentMethods.getValue()) {
        paymentMethods.next([{
          billingMethod: 'creditcardonfile',
          usertype: 'user',
          description: description
        }])
      }
    })
  }

  return {
    start: mount(root,
      fetchUserAccounts,
      subscribeToBillingAccounts
    )
  }
}
