import { forTarget, mount, on } from '~dk/core'
import { BehaviorSubject, fromEvent } from 'rxjs'
import { getInputValues } from '~dk/forms'
import { clearAlert, showAlert } from '../lib/shared'
import moment from 'moment'
import api from '../lib/api'

import type { Record } from '~dk/store'
import { retry } from 'rxjs/operators'

type Props = {
  basket: Record<any>
  location: Record<any>
  orderTypeVerified: Record<Boolean>
}

export default (root: HTMLElement, { location, basket, orderTypeVerified }: Props) => {
  const calendar$ = new BehaviorSubject([])
  const scheduleType$ = new BehaviorSubject('asap')

  basket.subscribe((data) =>
    setWhenValue(data)
  )

  calendar$.subscribe((data) => {
    setOrderDayOptions(data)
  })

  /**
   * Updates the Order Type section's when value to a human readable timestamp or ASAP
   *
   * @param data
   */
  function setWhenValue(data) {
    const target = forTarget<HTMLDivElement>(root, 'timemode')
    if (!target) return

    data.timewanted
      ? target.innerText = moment(data.timewanted, 'YYYYMMDD HH:mm').format('MM/DD [at] hh:mma')
      : target.innerText = 'ASAP'
  }

  /**
   * Sets the supported handoff methods for a given location
   */
  function setSupportedHandoffMethods() {
    const locationData = location.getValue() as any
    const methods: any[] = [{ value: 'pickup', label: 'Pickup' }]
    const selectElement = root.querySelector('[dk-input="order-type"]')!

    if (locationData.supportscurbside) methods.push({ value: 'curbside', label: 'Curbside' })
    if (locationData.supportsdispatch) methods.push({ value: 'dispatch', label: 'Delivery' })

    methods.forEach((method) => {
      const option = document.createElement('option')!
      option.value = method.value
      option.innerText = method.label
      selectElement.append(option)
    })
  }

  /**
   * If delivery is select, show the address fields
   */
  function enableAddressForDelivery() {
    const selectElement = root.querySelector('[dk-input="order-type"]')!

    fromEvent(selectElement, 'change').subscribe(
      (event) => {
        const target = event.target as HTMLSelectElement

        // Display the dispatch fields
        const addressFields = forTarget<HTMLDivElement>(root, 'delivery-address')!
        addressFields.style.display = (target.value === 'dispatch') ? 'block' : 'none'

        // Display the curbside fields
        const curbsideFields = forTarget<HTMLDivElement>(root, 'curbside-fields')!
        curbsideFields.style.display = (target.value === 'curbside') ? 'block' : 'none'

      }
    )
  }

  /**
   * Gets the location hours for the next 7 days
   */
  function fetchLocationHoursForTheWeek() {
    setSupportedHandoffMethods()
    enableAddressForDelivery()
    const locationData = location.getValue() as any
    const currentTime = moment()
    const fromDate = currentTime.format('YYYYMMDD')
    const toDate = currentTime.add(7, 'days').format('YYYYMMDD')

    api.restaurants.calendars(locationData.id, fromDate, toDate)
      .pipe(retry(3))
      .subscribe(({ response }) => calendar$.next(response.calendar))
  }

  /**
   * Updates the values for a given select element
   *
   * @param selectElement
   * @param value
   * @param label
   */
  function addOptionToSelect(selectElement: HTMLSelectElement, value: number | string, label: string) {
    const option = document.createElement('option') as HTMLOptionElement
    option.value = String(value)
    option.innerText = label

    selectElement.append(option)
  }

  /**
   * Transforms an olo date to a moment date object
   *
   * @param day
   * @param when
   */
  function getDate(day, when = 'start') {
    return moment(day[when], 'YYYYMMDD HH:mm')
  }

  /**
   * Populate the order days based on the store hours
   *
   * @param data
   */
  function setOrderDayOptions(data: any[]) {
    if (!data.length) return
    const formatLabel = (day) => `${day.weekday} ${getDate(day).format('MM / DD')}`

    const [today, tomorrow, ...week] = (data as any)[0].ranges as any[]
    const selectElement = root.querySelector('[dk-input="order-day"]')! as HTMLSelectElement
    const isToday = getDate(today).isSame(moment(), 'day')
    const todayLabel = isToday ? 'Today' : formatLabel(today)
    const tomorrowLabel = isToday ? 'Tomorrow' : formatLabel(tomorrow)

    addOptionToSelect(selectElement, '', 'Select Day...')
    addOptionToSelect(selectElement, 0, todayLabel)
    addOptionToSelect(selectElement, 1, tomorrowLabel)

    week.forEach((day, index) => {
      addOptionToSelect(selectElement, index + 2, formatLabel(day))
    })
  }

  /**
   * Populate the order time based on the selected day
   */
  function updateTimeOptionsOnDayChange() {
    fromEvent(root.querySelector('[dk-input="order-day"]')!, 'change')
      .subscribe(({ target }) => {
        const calendarData = calendar$.getValue() as any
        const index = (target as HTMLSelectElement).value
        const select = root.querySelector('[dk-input="order-time"]')! as HTMLSelectElement
        // Clear the select
        select.innerText = ''
        addOptionToSelect(select, '', 'Select time...')

        if (index === '' || !calendarData.length) return
        const record = calendarData[0].ranges[index]
        const start = getDate(record, 'start')
        const end = getDate(record, 'end')

        // Adding 15 minutes mutates the original
        const times = [start]
        while (end.isAfter(moment(times[times.length - 1]))) {
          const previous = times[times.length - 1].clone()
          times.push(previous.add(15, 'minutes'))
        }

        times.forEach((time) => {
          if (time.isAfter(moment().add(15, 'minutes'))) {
            const timeStr = time.format('hh:mma')
            addOptionToSelect(select, timeStr, timeStr)
          }
        })
      })
  }

  /**
   * Creates a function that handles swapping between ASAP and Scheduled for an order
   * @param type
   * @returns function
   */
  function setScheduleType(type) {
    return (event, _, target) => {
      const elements = target.parentElement.children as HTMLLinkElement[]
      if (scheduleType$.getValue() === type) return

      Array.from(elements).forEach((element) => {
        element.classList.remove('active')
      })

      target.classList.add('active')
      scheduleType$.next(type)
    }
  }

  /**
   * Autofill percentage tip amounts with buttons
   * 
   * @param event unused
   * @param _
   * @param target unused
   */
  function setTipAmount(event, _, target) {
    const tipInput = root.querySelector<HTMLFormElement>('[dk-input="order-delivery-tip"]')!
    const basketData = basket.getValue()
    const subTotal: number = +basketData.subtotal
    const tipPercentage: number = +_.values.tipPercentage
    const tipAmount = subTotal * tipPercentage
    tipInput.value = tipAmount.toFixed(2)
    tipInput.focus()
  }

  /**
   * Updates the delivery mode of a basket
   *
   * @param basketData
   * @param deliverymode
   */
  async function updateDeliveryMode(basketData, deliverymode: string) {
    if (basketData.deliverymode === deliverymode) return
    const { response } = await api.baskets.setDeliveryMode(basketData.id, { deliverymode }).toPromise()
    basket.next(response)
  }

  /**
   * Sets the time mode of a basket to ASAP
   */
  async function setTimeModeToAsap() {
    const basketData = basket.getValue() as any
    const { response } = await api.baskets.setTimeModeToAsap(basketData.id).toPromise()
    basket.next(response)
  }

  /**
   * Sets the time mode of a basket to scheduled
   * TODO: This function handles the form input as well as the request to the API. This should be refactored
   *
   * @param target
   * @param data
   */
  async function setTimeModeToScheduled(target, data) {
    const { id } = basket.getValue() as { id: any }
    const dayInput = data['order-day']
    const timeInput = data['order-time']

    if (dayInput === '') return showAlert(target, 'Please choose a day for your order')
    if (timeInput === '') return showAlert(target, 'Please choose a time for your order')
    clearAlert(target)

    const [{ ranges }] = calendar$.getValue() as any
    const day = getDate(ranges[dayInput])
    const time = moment(timeInput, 'hh:mma')
    const payload = {
      ismanualfire: false,
      year: day.format('YYYY'),
      month: day.format('M'),
      day: day.format('D'),
      hour: time.format('H'),
      minute: time.format('m')
    }

    const { response } = await api.baskets.setTimeWanted(id, payload).toPromise()
    basket.next(response)
  }

  /**
   * Closes the checkout modal
   */
  function closeModal() {
    const button = forTarget<HTMLLinkElement>(root, 'close-checkout-modal')
    button?.click()
  }

  /**
   * Adds a tip to the current basket
   *
   * @param basketId
   * @param tip
   */
  async function addTip(basketId: string, tip: number) {
    if (isNaN(tip)) return
  
    await api.baskets.updateTipAmount(basketId, {
      amount: tip,
    }).toPromise()
  }

  async function setBasketToDispatch() {
    const { id } = basket.getValue()
    const form = root.querySelector<HTMLFormElement>('[dk-action="update-order-details"]')!
    const data = getInputValues(form)

    await api.baskets.updateDispatchAddress(id, {
      streetaddress: data['order-street'],
      building: data['order-suite'],
      city: data['order-city'],
      zipcode: data['order-zip'],
      specialinstructions: data['special-instructions'],
      phonenumber: data['phonenumber'],
      isDefault: false,
    }).toPromise()

    await addTip(id, +data['order-delivery-tip'])
  }

  async function setBasketToCurbside () {
    const { id, customfields } = basket.getValue()
    const form = root.querySelector<HTMLFormElement>('[dk-action="update-order-details"]')!
    const data = getInputValues(form)

    const getField = (exp) => customfields.find((field) => field.label.match(exp))

    const submitCustomField = (fieldId, fieldValue) => {
      return api.baskets.updateCustomField(id, { id: fieldId, value: fieldValue })
        .pipe(retry(2))
        .toPromise()
    }

    const make = getField(/make/i)
    const model = getField(/model/i)
    const color = getField(/color/i)

    try {
      await Promise.all([
        submitCustomField(make.id, data['order-vehicle-make']),
        submitCustomField(model.id, data['order-vehicle-model']),
        submitCustomField(color.id, data['order-vehicle-color']),
      ])
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Updates the Order's schedule type
   *
   * @param event
   * @param _
   * @param target
   */
  async function updateOrderScheduleType(event, _, target) {
    event.preventDefault()

    const data = getInputValues(target)
    const basketData = basket.getValue()
    const scheduleType = scheduleType$.getValue()
    const deliverymode = data['order-type']!

    try {
      if (deliverymode === 'dispatch') await setBasketToDispatch()
      if (deliverymode === 'curbside') await setBasketToCurbside()
      await updateDeliveryMode(basketData, deliverymode)

      scheduleType === 'asap'
        ? await setTimeModeToAsap()
        : await setTimeModeToScheduled(target, data)

      clearAlert(target)
      closeModal()
      orderTypeVerified.next(true)
    } catch (error) {
      if (error?.response) showAlert(target, error.response?.message, 'error')
    }
  }

  return {
    start: mount(root,
      fetchLocationHoursForTheWeek,
      updateTimeOptionsOnDayChange,
      on('asap', 'click', setScheduleType('asap')),
      on('scheduled', 'click', setScheduleType('scheduled')),
      on('set-tip-percentage', 'click', setTipAmount),
      on('update-order-details', 'submit', updateOrderScheduleType)
    )
  }
}
