import { mount } from '~dk/core'

interface Props {
  clearStores: () => void
}

export default function (root: HTMLElement, { clearStores }: Props) {
  return {
    start: mount(root, () => {
      setTimeout(() => {
        clearStores()
        window.location.pathname = '/'
      }, 250)
    })
  }
}
