import api from '../lib/api';
import { forTarget, mount } from '~dk/core'
import { BehaviorSubject, fromEvent, Subject } from 'rxjs'
import { appendTo, fromTemplate } from '~dk/content';
import { toUSD, numberify } from '~dk/formatters';
import { getImageByGroupName, resizeOloImage } from '../lib/shared'
import { delay, retry } from 'rxjs/operators'

import type { Record } from '~dk/store'

type Props = {
  menu: Record<any>,
  selection: Record<any>,
  location: Record<any>,
  basket: Record<any>
}

export default (root: HTMLElement, { menu, selection, location, basket }: Props) => {
  const product = new Subject()
  const selectedModifiers = new BehaviorSubject({})
  const allOptions = new BehaviorSubject({})

  // set boolean for data pushed to Google or not
  let dataPushed = false

  /**
   * Parses the URL's search params for the product ID
   */
  function getProductId () {
    const params = new URLSearchParams(window.location.search)
    return params.get('id')
  }

  /**
   * Fetches the product from the menu. If available send the data to the observable
   * If not, redirect the user to the menu page.
   */
  function fetchProductFromMenu () {
    const productID = getProductId()
    if (!productID) return window.location.pathname = '/menu'

    const { categories } = menu.getValue()
    const products = categories.reduce((acc, category: any) => {
      const products = category.products
      return [...acc, ...products]
    }, []);

    const result = products.find((product: any) => {
      return product.id === +productID
    })

    product.next(result)
  }

  /**
   * Sets the preview image of a product
   *
   * @param productData
   */
  function setProductImage (productData: any) {
    const imageElements = root.querySelectorAll('[dk-value="product-image"]')! as NodeListOf<HTMLImageElement>
    const menuData = menu.getValue()
    const filename = getImageByGroupName(productData.images, 'marketplace-product')?.filename

    if (filename)
      for (const imageElement of Array.from(imageElements)) {
        imageElement.src = `${menuData.imagepath}${resizeOloImage(filename, 600, 300)}`
      }
  }

  /**
   * Populates the product name and description on the page
   *
   * @param productData
   */
  function setProductValues (productData: any) {
    const description = root.querySelector('[dk-value="description"]')!
    const name = root.querySelector('[dk-value="name"]')!
    description.innerHTML = productData.description
    name.innerHTML = productData.name
    setProductImage(productData)

    pushDataToDataLayer(productData)
  }

  /**
   * pushDataToDataLayer
   *
   * pushes data to GA's dataLayer
   * @param data
   */
  function pushDataToDataLayer (data: any) {

    // if data has already been pushed, bail
    if (dataPushed) return

    let categoryName = getProductCategoryName(data.id)
    let locationData = location.getValue()
    let basketData = basket.getValue()
    
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: 'USD', // (required) e.g. USD 
        value: numberify(data.cost), // (required) e.g. 7.49
        items: [
          {
            item_id: data.id, // (required) e.g. 56758602
            item_name: data.name, // (required) e.g. Create Your Own
            affiliation: locationData.name, // (suggested) e.g. GG Short Pump
            coupon: basketData.coupon, // (suggested) e.g. BOGO_summer24
            discount: basketData.discount, // (suggested) e.g. 2.50
            index: 0, // (optional) e.g. 3
            item_category: categoryName, // (suggested) e.g. valuebowls
            item_variant: null, // (suggested) e.g. medium
            location_id: null, // (suggested) e.g. ChIJIQBpAG2ahYAR_6128GcTUEo
            price: numberify(data.cost), // (suggested) e.g. 9.99
            quantity: 1 // (suggested) e.g. 1
          }
        ]
      }
    })

    dataPushed = true
  }

  /**
   * Get the product category name from its ID
   * @param productId 
   */
  function getProductCategoryName(productId) {
    const menuData = menu.getValue()
    const menuCategories = menuData.categories
    let categoryName = ''

    menuCategories.forEach(category => {
      category.products.forEach(product => {
        if (product.id === productId) {
          categoryName = category.name
          return
        }
      });
    });

    return categoryName
  }

  /**
   * Construct a list of all modifiers' id and name so that it can be used
   * for the bowl description above the "Add to bag" button
   *
   * @param productData
   * @param result
   */
  function aggregateOptionName (productData: any, result = {}) {
    const results = productData.modifiers?.map((modifier) => {
      return modifier.options?.reduce((acc, option) => {
        if (!option) return acc
        const sub = aggregateOptionName(option, acc)
        return {...acc, [option.id]: option.name, ...sub }
      }, result)
    })

    return results?.reduce((acc, result) => ({...result, ...acc}), {})
  }

  /**
   * Gets a list of product modifiers form the Olo API
   *
   * @param productData
   */
  function fetchProductModifiers (productData: any) {
    if (productData.modifiers) return

    api.products.modifiers(productData.id).pipe(delay(100), retry(10)).subscribe(
      ({ response }) => product.next({...productData, modifiers: response.optiongroups }),
      (error) => console.error({ error })
      )
  }

  /**
   * Looks through all selected options and sets the icon class to selected.
   * This adds the red ring around the icon
   *
   * @param element
   */
  function toggleSelected(element: Element) {
    const options = document.querySelectorAll('[option-id]')
    const selected = Object.values(selectedModifiers.getValue()).flat()

    options.forEach(option => {
      const icon = option.querySelector('div:first-child')!
      selected.includes(+option.getAttribute('option-id')!)
        ? icon.classList.add('selected')
        : icon.classList.remove('selected')
    })
  }

  /**
   * Adds an options to a the selectedModifiers observable.
   * - If the user clicks on an already selected option it will remove it from the list
   * - If the max is set to one option, it will replace the value in the selectModifiers object
   * - Otherwise it will continue to add options to a given group
   *
   * @param productId
   * @param optionGroup
   * @param target
   */
  function addOptionToProduct(productId: number, optionGroup, target: HTMLLinkElement) {
    const currentSelection = selectedModifiers.getValue()
    // const groupMax = Number(optionGroup.maxchoicequantity || optionGroup.mandatory)
    const groupMax = Number(optionGroup.maxselects || optionGroup.mandatory)
    const selections: number[] = currentSelection[optionGroup.id] || []
    const existing = selections.includes(productId)
    let newSelection = [...selections, productId]
    
    if (existing) newSelection = selections.filter((n) => n !== productId)
    if (groupMax === 1 && !existing) newSelection = [productId]
    
    // if groupMax is 0, there is no limit
    if (groupMax !== 0 && newSelection.length > groupMax) {
      alert('You may only choose up to ' + groupMax + ' of the current option.')
    } else {
      selectedModifiers.next({...currentSelection, [optionGroup.id]: newSelection })
      toggleSelected(target)
    }
  }

  /**
   * Resets the selected modifiers to only include the selected optionGroup
   *
   * @param optionGroupId
   */
  function resetSelectedModifier (optionGroupId) {
    const modifiers = selectedModifiers.getValue()
    selectedModifiers.next({ [optionGroupId]: modifiers[optionGroupId] })
  }

  /**
   * Resets the subgroup container and Loads any sub-options associated included with
   * an option. If there are children for the subgroup it's safe to assume that it's
   * a top level option. Reset the selected modifiers
   *
   * @param option
   * @param optionGroup
   */
  function loadAndSetOptionSubGroup(option, { id: optionGroupId }) {

    // console.log('optionGroupId', optionGroupId)
    // console.log('option.id', option.id)

    let optionGroupElement = document.querySelector('div[data-optiongroup="' + optionGroupId + '"')
    let clickedElement = document.querySelector('a[option-id="' + option.id + '"')
    let isSubGroup = clickedElement?.classList.contains('is-subgroup-option')
    let isSelected = clickedElement?.querySelector('.a-product__optionicon')?.classList.contains('selected')

    // console.log('optionGroupElement', optionGroupElement)
    // console.log('clickedElement', clickedElement)
    // console.log('isSubGroup', isSubGroup)

    // console.log('option.children', option.children)

    // if option has children, but is not subgroup...
    if (option.children && isSubGroup === false) {
      forTarget<HTMLElement>(root, 'option-subgroups')!.innerHTML = ''
      resetSelectedModifier(optionGroupId)
  
      // insert option modifier groups
      option.modifiers?.forEach((modifier) => {
        displayOptionGroups(modifier, 'option-subgroups')
      })
    }

    // if option has children and is subgroup
    if (option.children && isSubGroup === true) {
  
      // insert option modifier groups
      option.modifiers?.forEach((modifier) => {

        // console.log('modifier.id', modifier.id)
        
        // if option is de-selected
        if (!isSelected) {
          // remove the modifiers
          let removeOption = document.querySelector('div[data-optiongroup="' + modifier.id + '"]')
          removeOption?.remove()
        } else {
          // else, display the modifiers
          displayOptionGroupsNext(modifier, optionGroupElement)
        }

      })
    }
  }

  /**
   * handle click events on the option by selecting the option and loading any modifiers
   * associated with it.
   *
   * @param optionElement
   * @param option
   * @param optionGroup
   */
  function setClickHandlersOnOption(optionElement: Element, option, optionGroup) {
    fromEvent(optionElement, 'click')
      .subscribe((e) => {
        const target = e.currentTarget as HTMLLinkElement
        addOptionToProduct(option.id, optionGroup, target)
        loadAndSetOptionSubGroup(option, optionGroup)
      })
  }

  function addImageToOptionElement (option, optionElement: Element) {
    const image = root.querySelector(`img[alt="${option.name}"]`) as HTMLImageElement

    if (image) {
      const targetDiv = optionElement.querySelector('div:first-child')! as HTMLElement

      targetDiv.innerHTML = ''
      targetDiv.appendChild(image.cloneNode())
    }
  }

  /**
   * Iterates through a list of options and adds it's element to the list of options for the group
   *
   * @param optionGroup
   * @param element
   */
  function setOptionsForOptionGroup(optionGroup, element: HTMLElement) {
    element.dataset.optiongroup = optionGroup.id // add optiongroup id to wrapper element
    optionGroup.options.forEach((option) => {
      // If there is a cost associated with an option, update the cost to USD
      if (option.cost !== undefined) option.price = toUSD(option.cost)
      const optionElement = fromTemplate('option', option)!
      addImageToOptionElement(option, optionElement)

      if (!option.adjustsparentprice) optionElement.querySelector('[dk-target]')!.remove()
      // Add option ids to the element. This is used to toggle the selection
      optionElement.setAttribute('option-id', option.id)
      if (!optionGroup.description.includes('Select Size')) {
        optionElement.classList.add('is-subgroup-option')
      }
      appendTo(element, 'options', optionElement)
      setClickHandlersOnOption(optionElement, option, optionGroup)
      toggleSelected(optionElement) // Removes selected from all of the options in the group
    })
  }

  /**
   * Add the option groups to the page
   *
   * @param modifier
   * @param target
   */
  function displayOptionGroups(modifier: any, target ) {
    const element = <HTMLElement> fromTemplate('option-group', modifier)!
    setOptionsForOptionGroup(modifier, element)
    appendTo(root, target, element)
  }

  /**
   * Add the option group(s) immediately after parent options
   *
   * @param modifier
   * @param target
   */
  function displayOptionGroupsNext(modifier: any, target ) {
    const element = <HTMLElement> fromTemplate('option-group', modifier)!
    setOptionsForOptionGroup(modifier, element)
    target.after(element)
  }

  /**
   * Sets the initial set of options if available
   *
   * @param productData
   */
  function setOptionGroups(productData) {
    productData?.modifiers?.forEach((modifier) => {
      displayOptionGroups(modifier, 'option-groups')
    })
  }

  /**
   * Adds the currently selected modifiers to the product
   *
   * @param modifiers
   */
  function addSelectedModifiersToTheProduct (modifiers) {
    const productid = getProductId()
    const options = Object.values(modifiers).flat().join(',')

    selection.next({ productid, options })
  }

  /**
   * Based on the list of selected options, update the text above the add to bag button
   * so that it displays all of the options selected
   *
   * @param selectedModifiers
   */
  function displaySelectedOptions (selectedModifiers) {
    const target = forTarget<HTMLElement>(root, 'selected-options')!
    if (!target) return
    const modifiers = Object.values(selectedModifiers).flat()
    const selected =  modifiers.map((mod) => (allOptions.getValue())[String(mod)])

    target.innerHTML = selected.join (', ')
    target.style.marginBottom = selected.length ? '1.5em' : '0'
  }

  /**
   * Gets the current category and it's products for a product
   */
  function getCategory (matchExpression: RegExp) {
    const menuData = menu.getValue()
    return menuData?.categories?.find((category) => category.name.match(matchExpression))
  }

  /**
   * If the current product is a bowl, show the "Name your bowl" link
   *
   * @param productData
   */
  function showNameYourBowl (productData: any) {
    const category = getCategory(/bowl/i)
    const isBowl = category?.products.some((product) => product.chainproductid === productData.chainproductid)

    if (isBowl) forTarget<HTMLDivElement>(document, 'bowlName')!.style.display = 'block'
  }

  product.subscribe(async (productData: any) => {
    showNameYourBowl(productData)
    setProductValues(productData)
    fetchProductModifiers(productData)
    setOptionGroups(productData)

    selection.next({ productid: productData.id })
    allOptions.next(aggregateOptionName(productData))
  })

  selectedModifiers.subscribe((modifiers) => {
    addSelectedModifiersToTheProduct(modifiers)
    displaySelectedOptions(modifiers)
  })

  return {
    start: mount(root,
      fetchProductFromMenu,
      )
    }
  }
