const formatters = {
  'us-dollars': toUSD,
  'title-case': titleCase,
  'convert-to-number': numberify
}

export function setFormat (root: Element) {
  const elems = root.querySelectorAll('[dk-format]')

  Array.from(elems).forEach((elem) => {
    const format = elem.getAttribute('dk-format')!
    const targetFormat = formatters[format]

    if (targetFormat) {
      elem.innerHTML = targetFormat?.(elem.innerHTML)
    }
  })
}

/**
 * Converts a number to currency
 *
 * @param num
 */
export function toUSD(num: number | string): string {
  const value = Number(num)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(value)
}

/**
 * Convert string to number
 * 
 * @param num
 * @returns 
 */
export function numberify(num: Maybe<string>) {
  const value = String(num).replace('$', '') || 0
  return value
}

export function titleCase(str: string) {
  const target = str.toLowerCase()
  return target.split(' ').map(([first, ...rest]) => {
   return [first.toUpperCase(), ...rest].join('')
  }).join(' ')
}

