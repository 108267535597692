import { forTarget, mount } from '~dk/core'
import { replaceContents } from '~dk/content'
import api from '../lib/api'

export default function (root: HTMLElement) {

  function curbsideCheckIn () {
    console.log('curbsideCheckIn called')
    const curbsideContainer = forTarget<HTMLElement>(root, 'curbside-checkin-container')
    console.log('curbsideContainer', curbsideContainer)
    if (!curbsideContainer) return
    sendCurbsideCheckIn()
  }

  /**
   * Watch for curbside arrival click
   */
   function sendCurbsideCheckIn () {

    console.log('sendCurbsideCheckIn fired')

    // get the orderid param
    const urlParams = new URLSearchParams(window.location.search)
    const orderId = urlParams.get('orderid')
    const curbsideContent = forTarget<HTMLElement>(root, 'curbside-content')

    // bail out if there's no order id or curbsideContent
    if (!orderId || !curbsideContent) {
      console.log('orderId', orderId)
      console.log('curbsideContent', curbsideContent)
      return
    }

    curbsideContent.addEventListener('click', async(event) => {
      console.log('Container clicked')
      const { target } = event
      if (target && ((target as HTMLElement).id === 'curbsideCheckin')) {
        console.log('Send arrival info to Olo.')
        const payload = {
          message: 'Customer has arrived'
        }
        const { response } = await api.orders.arrivalNotice(orderId, payload).toPromise()
        if (response && response.arrivalstatus === 'Arrived') {
          window.alert('Got it! Your order will be out shortly.');
          replaceContents(curbsideContent, 'curbside-notice', 'Thanks for letting us know you’re here. We’ll be out with your order shortly.')
        }
      }
    })
  }

  return {
    start: mount(root,
      curbsideCheckIn
    )
  }
}