import { BehaviorSubject } from 'rxjs'
import Inputmask from 'inputmask'

type AlertType = 'warning' | 'error' | 'success' | 'info'

/**
 * TODO: Add function description
 *
 * @param input
 * @param record
 * @param callback
 */
export const parseInput = (input: Maybe<HTMLInputElement>, record: BehaviorSubject<object>, callback?: Function) => {
  const subject = record.getValue()
  const key = input?.getAttribute('dk-input') as string
  subject[key] = input?.value

  record.next(subject)
  callback && callback()
}

/**
 * TODO: Add function description
 *
 * @param type
 * @param message
 * @param root
 * @returns
 */
export const alert = (type: AlertType, message: string, root: HTMLElement) => {
  const elem = root.querySelector(`[dk-alert="${type}"]`) as HTMLDivElement

  if (!elem) return

  elem.style.display = 'block'
  elem.classList.add(type)
  elem.innerText = message
}

/**
 * Takes the input from a form and converts it to an object
 *
 * @param target
 * @returns
 */
export const convertFormInputToObject = (target: HTMLFormElement) => {
  const inputs = target.querySelectorAll('[dk-input]')
  return Array.from(inputs).reduce((acc, input: HTMLInputElement | HTMLSelectElement) => {
    return { ...acc, [input.getAttribute('dk-input')!]: input.value }
  }, {})
}

/**
 * Alias of convertFormInputToObject
 */
export const getInputValues = convertFormInputToObject

export function setInputValues(root: Element, user: object) {
  const inputs = root.querySelectorAll('[dk-input]')! as NodeListOf<HTMLInputElement>;

  Array.from(inputs).forEach((input) => {
    const key = input.getAttribute('dk-input')!
    input.value = user[key]
  });
}

export function maskInputs(root: Element) {
  const elements = root.querySelectorAll('[dk-input-mask]')

  elements.forEach((element) => {
    Inputmask(element.getAttribute('dk-input-mask')).mask(element)
  })
}
