import api from '../lib/api'
import { forTarget, mount } from '~dk/core'
import type { Record } from '~dk/store'
import {
  addFavoriteToBasket,
  addOrderCardToHomepageFavorites,
  fetchFavorites,
  getStar,
  hideStar,
} from '../utils/favorites'
import { fromEvent } from 'rxjs'
import { clearContents } from '~dk/helpers'

interface Props {
  account: Record<any>
  faves: Record<any>
  basket: Record<any>
}

export default function (root: HTMLElement, { account, faves, basket }:  Props) {
  /**
   * Fetch the user's faves when the account data updates
   *
   * @param accountData
   */
  async function getUserFaves (accountData) {
    const { response } = await api.users.getFaves(accountData.authtoken).toPromise()
    faves.next(response?.faves || [])
  }

  /**
   *
   */
  function showNoFaves () {
    const noFavesMessage = forTarget<HTMLDivElement>(root, 'no-favorites')!.cloneNode(true)
    forTarget<HTMLDivElement>(root, 'favorite-container')?.appendChild(noFavesMessage)
  }

  /**
   *
   * @param card
   * @param favorite
   */
  function handleFavoriteActions (card: Element, favorite) {
    const { authtoken } = account.getValue()
    const star = getStar(card, 'unfavorite')
    hideStar(card, 'favorite')

    fromEvent(star, 'click').subscribe((_) => {
      if (!confirm('Are you sure you want to remove this favorite?')) return
      api.users.deleteFavorite(authtoken, favorite.id).subscribe(async () => {
        const newFaves = await fetchFavorites(authtoken, faves)
        console.log(newFaves)
      })
    })
  }


  /**
   * Reorder favorite or previous order
   *
   * @param element
   * @param order
   */
  function handleReorder (element: Element, order: any) {
    fromEvent(forTarget<HTMLLinkElement>(element, 'reorder-button')!, 'click')
      .subscribe((_event) => addFavoriteToBasket(basket, account, order))
  }

  /**
   *
   */
  function subscribeToFaves () {
    return faves.subscribe((data) => {
      // Reset the target container, this handles the case where a favorite is removed
      clearContents(forTarget<HTMLElement>(root, 'favorite-container')!)

      if (!data?.length) return showNoFaves()

      const favorite = data[data.length - 1] // last favorite
      const element = addOrderCardToHomepageFavorites(root, 'favorite-container', favorite)

      handleFavoriteActions(element, favorite)
      handleReorder(element, favorite)
    })
  }

  /**
   * creates a subscription to the account observable
   */
  function subscribeToAccount () {
    return account.subscribe(async (data) => {
      if (!data) return root.style.display = 'none'
      root.style.display = 'block'
      if (!faves.getValue()) await getUserFaves(data)
    })
  }


  return {
    start: mount(root,
      subscribeToAccount,
      subscribeToFaves
    )
  }
}
