import type { Record } from '~dk/store'
import { forTarget, forTargets, mount, on } from '~dk/core'
import { fromTemplate } from '~dk/content'
import { clearContents } from '~dk/helpers'
import { getInputValues, maskInputs } from '~dk/forms'
import { fromEvent } from 'rxjs'
import api from '../lib/api'

interface Props {
  paymentMethods: Record<any>
  billingAccounts: Record<any>
  basket: Record<any>
}

interface PaymentMethod {
  billingMethod: 'creditcardonfile' | 'creditcard' | 'billingaccount' | 'cash' | 'storedvalue' | 'prepaid'
  usertype: 'user' | 'guest'
  description: String,
  amount: number
}

export default function (root: HTMLElement, { paymentMethods, billingAccounts, basket }: Props) {
  function closeModal (target) {
    const modal = forTarget<HTMLElement>(root, target)
    if (modal) modal.style.display = 'none'
  }

  function handleRemovePaymentMethod (index, element: HTMLElement) {
    on('remove-payment-method', 'click', () => {
      const methods = paymentMethods.getValue() as PaymentMethod[]
      methods.splice(index, 1)
      paymentMethods.next(methods)
    })(element)
  }

  function handleAmountInput (element: HTMLElement, method, index) {
    const amount = element.querySelector('input')!
    amount.value = (method.amount || 0).toFixed(2)
    amount.setAttribute('name', `amount-${index}`)
    amount.setAttribute('id', `amount-${index}`)
    const methods = paymentMethods.getValue() as PaymentMethod[]

    fromEvent(amount, 'focusout').subscribe((event) => {
      methods[index].amount = +(event.target as HTMLInputElement).value
      paymentMethods.next(methods)
    })
  }

  function handleEditsToPaymentMethod (method, element: HTMLElement) {
    if (method.billingMethod !== 'creditcardonfile') {
      const editButton = element.querySelector('[dk-action="edit-payment-method"]')! as HTMLElement
      editButton.style.display = 'inline'

      fromEvent(editButton, 'click').subscribe(() => {
        if (method.billingMethod === 'creditcard') {
          const modal = forTarget<HTMLElement>(root, 'credit-card-fields')!
          modal.style.display = 'flex'
        } else if (method.billingMethod === 'storedvalue') {
          const modal = forTarget<HTMLElement>(root, 'gift-card-fields')!
          modal.style.display = 'flex'
        }
      })
    }
  }

  /**
   *
   * @param method
   * @param index
   * @param container
   */
  function addPaymentMethodCard (method, index, container) {
    const element = fromTemplate('billing-account', method)! as HTMLElement
    element.setAttribute('index', index)
    handleAmountInput(element, method, index)

    handleRemovePaymentMethod(index, element)
    handleEditsToPaymentMethod(method, element)

    maskInputs(element)
    container.appendChild(element)
  }

  function addCreditCard () {
    const basketData = basket.getValue()
    const inputValues = getInputValues(root as HTMLFormElement) as any
    const [expiryMonth, expiryYear] = inputValues['expiration-date'].split('/')

    const currentPaymentMethods = (paymentMethods.getValue() as Maybe<PaymentMethod[]> || [])
      .filter((method) => method.billingMethod !== 'creditcard')

    const method = {
      billingMethod: 'creditcard',
      description: 'Credit Card',
      amount: 0.0,
      zip: inputValues['zip-code'],
      expiryMonth: `${+expiryMonth}`,
      expiryYear: `20${expiryYear}`,
      tipPortion: basketData.tip, // tip may only be applied to one credit card at the moment, not applicable to gift card
    }

    paymentMethods.next([...currentPaymentMethods, method])
    closeModal('credit-card-fields')
  }

  /**
   * As it sits, you can only have one credit card for the order and still use the CC Submission frame.
   * That beings the case, If a credit card is added, hide the button
   *
   * @param paymentMethods
   */
  function hideAddCreditCard (paymentMethods: Maybe<PaymentMethod[]>) {
    const hasCreditCard = paymentMethods?.some((method) => method.billingMethod === 'creditcard')
    const button = forTarget<HTMLElement>(root, 'add-credit-card-button')!

    button.style.display = hasCreditCard ? 'none' : 'block'
  }

  /**
   * By default, when the user loads the checkout page, if they have a billing account it's loaded as the payment method
   * If the user removes it, a button is displayed that will allow them to add it back
   *
   * @param methods
   * @param button
   */
  function toggleSavedPaymentMethodButton (methods: Maybe<PaymentMethod[]>, button: HTMLLinkElement) {
    const accountsData = billingAccounts.getValue()
    const hasBillingAccount = !!accountsData?.billingaccounts?.length
    const isSetAsPaymentMethod = methods?.some((method) => {
      return method.billingMethod === 'creditcardonfile'
    })

    if (!hasBillingAccount) return
    button.style.display = isSetAsPaymentMethod ? 'none' : 'block'
  }

  /**
   * In order to better identify the card that's on file, this function updates the button's text to the description
   * saved on Olo if a previous billing account exists.
   *
   * @param account
   */
  function setSavedPaymentMethodButtonText (account: Maybe<any>) {
    const buttonText = forTarget<HTMLElement>(root, 'previous-payment-method')!

    buttonText.innerText = account.description
  }

  /**
   *
   * @param methods
   */
  function handleSavePaymentMethodButton (methods: Maybe<PaymentMethod[]>) {
    const button = forTarget<HTMLLinkElement>(root, 'add-previous-credit-card-button')
    const billingAccountData = billingAccounts.getValue() as Maybe<{ billingaccounts: any[] }>
    const account = billingAccountData?.billingaccounts?.[0] as Maybe<any>

    if (!button || !account) return

    toggleSavedPaymentMethodButton(methods, button)
    setSavedPaymentMethodButtonText(account)

    fromEvent(button, 'click').subscribe(() => {
      paymentMethods.next([...methods || [], {
        billingMethod: 'creditcardonfile',
        usertype: 'user',
        description: account.description
      }])
    })
  }

  function resetGiftCardInputs () {
    const inputs = root.querySelectorAll('[dk-input^="gift-card"]') as NodeListOf<HTMLInputElement>
    for (const input of Array.from(inputs)) {
      input.value = ''
    }
  }

  function updateGiftCard () {
    const basketData = basket.getValue()
    const methods = paymentMethods.getValue() as Maybe<PaymentMethod[]>
    const values = getInputValues(root as HTMLFormElement)
    const cardNumber = values['gift-card-number']
    const pin = values['gift-card-pin']

    if (!cardNumber) return

    api.baskets.getBillingSchema(basketData.id).subscribe(
      ({ response }) => {
        const scheme = response.billingschemes?.find((scheme) => scheme.type === 'giftcard')
        const pinIsMandatory = scheme?.fields.some((field) => field.name === 'pin' && field.isMandatory)
        const billingFields = [{ name: 'number', value: cardNumber }]

        if (pinIsMandatory || pin !== '') billingFields.push({ name: 'pin', value: pin })

        const method = {
          amount: 0,
          billingFields,
          billingMethod: 'storedvalue',
          billingSchemeId: scheme?.id,
          description: `Gift Card x-${cardNumber.substr(cardNumber.length - 4)}`
        }

        paymentMethods.next([...methods || [], method])

        closeModal('gift-card-fields')
        resetGiftCardInputs()
      }
    )
  }

  /**
   *
   */
  function subscribeToPaymentMethods () {
    const container = forTarget<HTMLDivElement>(root, 'payment-methods-container')!
    const placeholder = forTarget<HTMLDivElement>(root, 'payment-method-placeholder')!

    paymentMethods.subscribe((methods: Maybe<PaymentMethod[]>) => {
      clearContents(container)

      hideAddCreditCard(methods)
      handleSavePaymentMethodButton(methods)

      if (!methods?.length) return placeholder.style.display = 'block'

      placeholder.style.display = 'none'

      methods.forEach((method, index) => {
        addPaymentMethodCard(method, index, container)
      })

      const amounts = forTargets<HTMLElement>(root, 'payment-method-amount')

      for (const amount of Array.from(amounts)) {
        amount.style.display = methods.length > 1 ? 'flex' : 'none'
      }
    })
  }

  return {
    start: mount(root,
      subscribeToPaymentMethods,
      on('update-credit-card', 'click', addCreditCard),
      on('update-gift-card', 'click', updateGiftCard),
    )
  }
}
