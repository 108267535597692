// TODO: Separate upsell and summary into their own modules.
import { forTarget, mount, on } from '~dk/core'
import api from '../lib/api';
import { fromTemplate, replaceContents, setContents, setValue } from '~dk/content';
import type { Record } from '~dk/store'
import { toUSD } from '~dk/formatters';
import { addSingleProductToBasket, findOrCreateOloBasket, getImageByGroupName, resizeOloImage } from '../lib/shared'

interface Props {
  account: Record<any>
  basket: Record<any>
  location: Record<any>
  menu: Record<any>
  selection: Record<any>
}

export default (root: HTMLElement, { account, menu, basket, location, selection }: Props) => {
  let productTemplate: Maybe<HTMLElement>

  /**
   * TODO: Removing a product blows up when compiling for production.
   * Creating a nex variable based off of the original observable fixes this.
   * I'm not sure why, but it's probably something simple.
   */
  const basketObservable = basket

  /**
   * Displays the count on the "My Bag" button
   *
   * @returns
   */
  function setBagContent () {
    const data = basket?.getValue()

    if (data?.products) {
      const element = document.querySelector('[dk-action="checkout-button"]')!
      const count = data.products.length
      if (count) return element.innerHTML = `My Bag (${count})`
      element.innerHTML = 'Order'
    }
  }

  /**
   * displays a list of all products current in the bag on the sidebar
   */
  function showProductsInBag () {
    const basketData = basket.getValue()
    const container = document.querySelector('[data-dk-bag-products]')
    productTemplate ||= container?.querySelector('[dk-template]')

    if (!basketData?.products || !container || !productTemplate) return

    // reset container contents
    container.innerHTML = ''
    container.prepend(productTemplate)

    displayProductCards(basketData.products, productTemplate, container)
  }

  /**
   * Creates a list of options and appends them to an unordered list
   *
   * @param elem
   * @param product
   */
  function addOptionsToCard(elem: HTMLElement, product: any) {
    const options = product.choices.map(choice => `<li>${choice.name}</li>`)
    elem.querySelector('ul')!.innerHTML = options.join('')
  }

  /**
   * Loop through each product in the basket and display the card
   *
   * @param products
   * @param template
   * @param container
   */
  function displayProductCards(products: any[], template: Maybe<HTMLElement>, container: Element) {
    products.forEach((product: any) => {
      const elem = template?.cloneNode(true) as HTMLElement

      elem.removeAttribute('dk-template')
      elem.querySelector('[dk-value="title"]')!.innerHTML = product.name
      elem.querySelector('[dk-value="price"]')!.innerHTML = toUSD(product.totalcost)
      elem.setAttribute('product-id', product.id)
      addOptionsToCard(elem, product)
      setValue(elem, 'quantity', product.quantity)

      container.append(elem)
      addRemoveItemEventListener(elem)
    })
  }

  /**
   * Appends the remove item event listener to each new element
   *
   * @param elem
   */
  function addRemoveItemEventListener (elem: HTMLElement) {
    const productId = elem.getAttribute('product-id')!
    const { id } = basket.getValue()

    on('remove-product', 'click', () => {
      api.baskets.removeItem(id, productId).subscribe(({ response }) => {
        basketObservable.next(response)
      })
    })(elem)

    on('increase-quantity', 'click', async () => {
      await updateProductQuantity(productId, 1)
    })(elem)

    on('decrease-quantity', 'click', async () => {
      await updateProductQuantity(productId, -1)
    })(elem)
  }

  /**
   * Increase or decreases the count of a specific product
   *
   * @param productId
   * @param quantity
   */
  function updateProductQuantity(productId: any, quantity: number) {
    const { id, products } = basket.getValue()
    const product = products.find(product => product.id === Number(productId))
    if (!product) return
    if (product.quantity === 0 && quantity < 1) return

    const payload = {
      productid: product.productId,
      options: product.choices?.map(c => c.optionid).join(','),
      quantity: product.quantity + quantity
    }

    api.baskets.updateProduct(id, product.id, payload)
      .subscribe(({ response }) => basket.next(response))
  }

  /**
   * Based on the products currently in the basket, set the count inside the item-count pill on the mobile nav bar
   *
   * @param data
   */
  function setProductCountForMobilePill (data: any) {
    if (!data) return
    const count = data.products.reduce((acc, product) => (product?.quantity || 0) + acc, 0)
    const bullet = root.querySelector('[dk-target="product-count"]')!

    if (bullet) bullet.textContent = count as string
  }

  /**
   * Toggles between the bag and order buttons depending on if you have items in your basket or not
   *
   * @param data
   */
  function toggleBagMobileButtons (data: any) {
    const bagButton = root.querySelector('[dk-target="bag-button"]')! as HTMLElement
    const orderButton = root.querySelector('[dk-target="order-button"]')! as HTMLElement
    if (!bagButton || !orderButton) return

    if (data?.products?.length) {
      bagButton.style.display = 'block'
      orderButton.style.display = 'none'
      return
    }


    bagButton.style.display = 'none'
    orderButton.style.display = 'block'
  }

  /**
   * Hides or shows the checkout and summary button if there are products in the basket
   */
  function toggleCheckoutButton () {
    const data = basket.getValue() || {}
    const targets = ['checkout-button', 'summary-container']

    targets.forEach((target) => {
      const element = forTarget<HTMLElement>(root, target)
      if (element) element.style.display = data.products?.length ? 'block' : 'none'
    })
  }

  /**
   * Displays a summary of the order in the sidebar
   */
  function showOrderSummary () {
    const basketData = basket.getValue()
    if (!basketData) return
    const taxes = basketData.taxes?.reduce((acc, tax) => acc + tax.tax, 0)
    const content = fromTemplate('bag-summary', {
      subtotal: toUSD(basketData.subtotal),
      total: toUSD(basketData.total),
      discount: toUSD(basketData.discount),
      taxes: toUSD(taxes)
    })

    setContents(root, 'summary-container', content)
  }

  /**
   * Sets the background image for a upsell item
   *
   * @param product
   * @param element
   */
  function setBackgroundImageForUpsellItem (product, element: HTMLDivElement) {
    const backgroundImage = getImageByGroupName(product.images, 'mobile-app')
    const { imagepath } = menu.getValue()

    element.querySelector('div:first-child')!.setAttribute('style', `
        background-image: url("${imagepath + resizeOloImage(backgroundImage.filename, 500, 500)}");
        background-size: cover;
        background-position: center;
      `)
  }

  /**
   * Displays a list of upsell items
   */
  function displayUpsellItems () {
    const menuData = menu.getValue()
    if (!menuData) return

    // Get deserts and appetizers from the menu
    const appetizers = menuData.categories.find(cat => cat.name === 'Appetizers')
    const deserts = menuData.categories.find(cat => cat.name === 'Desserts')

    // Take 2 appetizers and a desert
    const products = [...appetizers?.products.slice(0, 2), deserts?.products[0]]

    // Cycle through each product and add it to the root element. For some reason,
    // specific restaurants will return an 'undefined' product. In that case, filter that crap out.
    const elements = products.filter((product) => !!product).map((product) => {
      const element =  fromTemplate('upsell-item', { ...product, cost: toUSD(product?.cost || 0) })! as HTMLDivElement
      element.setAttribute('href', `product?up=${product.chainproductid}#/${product.id}`)
      setBackgroundImageForUpsellItem(product, element)
      return element
    })

    replaceContents(root, 'upsell-container', elements)
  }

  // Actions to perform when the basket changes
  basket.subscribe((data) => {
    showProductsInBag()
    showOrderSummary()
    setBagContent()
    toggleCheckoutButton()
    displayUpsellItems()
    toggleBagMobileButtons(data)
    setProductCountForMobilePill(data)
  })

  function getRecipient () {
    const input = forTarget<HTMLInputElement>(root, 'name-your-bowl-input')
    return input?.value
  }

  return {
    async onLoad () {
      on('checkout-button', 'click', (event) => {
        event.preventDefault()
        const basketData = setBagContent()
        if (!basketData) window.location.pathname = '/menu'
      })(document)
    },

    start: mount(root,
      on('add-to-bag', 'click', async ()=> {
        await findOrCreateOloBasket(location, basket, account)
        const basketData = basket.getValue()
        const recipient = getRecipient()
        const payload = { ...selection.getValue(), quantity: 1, recipient }

        addSingleProductToBasket(basketData.id, payload, basket,
          document.querySelector('[dk-target="product-options"]')! as HTMLElement)
      })
    )
  }
}

