import type { Record } from '~dk/store'
import { mount, on, trigger } from '~dk/core'
import { getInputValues, maskInputs, setInputValues } from '~dk/forms'
import { appendTo, fromTemplate, replaceContents } from '~dk/content'
import api from '../lib/api'
import { showAlert } from '../lib/shared'

type Props = {
  user: Record<any>,
  account: Record<any>,
  punchhLocations: Record<any>
  location: Record<any>
  clearStores: () => void
}

export default function (root, { clearStores, user, punchhLocations, location }: Props) {

  /**
   * Redirects the user to the home page if they aren't authenticated
   */
  function redirectToHomeIfUnauthed () {
    if (!user.getValue()) window.location.pathname = '/'
  }

  /**
   * Signs the user out
   * TODO: sign out on Punchh as well
   */
  function signOut () {
    clearStores()
    window.location.pathname = '/'
  }

  /**
   * Add a list of store hours to a card
   *
   * @param storeTimes
   * @param card
   */
  function addStoreHoursToCard (storeTimes, card: Element) {
    storeTimes?.forEach((time) => {
      const li = document.createElement('li')
      li.innerHTML = `<strong>${time.day}</strong>: ${time.start_time} - ${time.end_time}`
      appendTo(card, 'location-hours', li)
    })
  }

  async function getLocationFromFavoriteLocation (extRef) {
    const { response } = await api.restaurants.getByRef(extRef).toPromise()
    const newLocation = response.restaurants[0]
    location.next(newLocation)
    return newLocation
  }

  /**
   * Sets the users location based on their favorite and redirects them to the menu page
   *
   * @param card
   * @param locationData
   */
  function handleOrderFromRestaurantButton (card: Element, locationData: any) {
    on('order-from-location', 'click', async () => {
      console.log(locationData)
      await getLocationFromFavoriteLocation(locationData.store_number)
      window.location.pathname = '/menu'
    })(card as HTMLElement)
  }

  /**
   * Finds the user's location in the Punchh location array and adds it to the page.
   */
  function displayTheFavoriteLocationCard () {
    const userData = user.getValue()
    const punchhLocationData = punchhLocations.getValue()
    const locationData = punchhLocationData.find((data) => {
      return data.location_id === +userData.favourite_locations
    })
    const card = fromTemplate('favorite-location', locationData)!
    addStoreHoursToCard(locationData?.store_times, card)

    replaceContents(root, 'favorite-location-info', card)
    handleOrderFromRestaurantButton(card, locationData)
  }

  /**
   * Prepares a form and payload to be submitted to Punchh
   *
   * @param callback
   */
  function punchhRequest(callback) {
    return function(event, _, form) {
      event.preventDefault()
      const data = getInputValues(form)
      const { authentication_token } = user.getValue()

      callback(form, { user: data, authentication_token })
    }
  }

  /**
   * Updates the current users account
   *
   * @param event
   * @param _
   * @param target
   */
  const updateUserAccount = punchhRequest((form, payload) => {
    api.users.update(payload)
      .subscribe(
        ({ response }) => {
          user.next(response)
          showAlert(form, "Your profile was updated :)", 'info')
        },
        () => {
          showAlert(form, "Oops, something went wrong", 'error')
        }
      )
  })

  /**
   * updates the user's password
   *
   * @param event
   * @param _
   * @param target
   */
  const updatePassword = punchhRequest((form, payload) => {
      // Check if the passwords match. If not, don't do nothing. :)
      if (payload.user.password !== payload.user.password_confirmation) {
        showAlert(form, "Hmm, those password didn't match", 'warning')
        return
      }

      api.users.changePassword(payload).subscribe(
        () => {
          showAlert(form, 'Successfully changed password', 'info')
        },
        ({ status }) => {
          if (status === 422) showAlert(form, "Sorry, you can't use a previously used password", 'warning')
        }
      )
  })

  /**
   * Updates the users' favorite location
   * @param event
   * @param _
   * @param target
   */
  const updateFavoriteLocation = punchhRequest((_, payload) => {
    api.users.update(payload)
      .subscribe(({ response }) => {
        user.next(response)
        displayTheFavoriteLocationCard()
      })
  })

  return {
    start: mount(root,
      redirectToHomeIfUnauthed,
      () => maskInputs(root),
      on('sign-out', 'click', signOut),
      on('profile-form', 'submit', updateUserAccount),
      on('password-reset', 'submit', updatePassword),
      on('favorite-location', 'submit', updateFavoriteLocation),
      trigger('profile-form', (element) => setInputValues(element, user.getValue())),
      trigger('favorite-location', displayTheFavoriteLocationCard)
    )
  }
}
