import { forTarget, mount, on } from '~dk/core'
import type { Record } from '~dk/store'
import {
  addOrderCardToHomepageFavorites,
  createBasketFromPreviousOrder,
  createFavoriteHandler,
  fetchOrderHistory,
  getStar,
  hideStar,
} from '../utils/favorites'
import { fromEvent, Subject } from 'rxjs'

interface Props {
  account: Record<any>
  previousOrders: Record<any>
  faves: Record<any>
  basket: Record<any>
}

export default function (root: HTMLElement, { account,  previousOrders, faves, basket }:  Props) {
  const modal = forTarget<HTMLDivElement>(root, 'new-favorite-modal')!
  const order$ = new Subject<any>()

  /**
   * Fetch the user's faves when the account data updates
   *
   */
  async function getOrderHistory () {
    const accountData = account?.getValue()
    if (!accountData) return

    await fetchOrderHistory(accountData.authtoken, previousOrders)
  }

  /**
   *
   */
  function subscribeToPreviousOrders () {
    return previousOrders.subscribe((data) => {
      order$.next(data?.orders[0])
    })
  }

  /**
   *
   * @param card
   * @param order
   */
  function handlePreviousOrderActions (card, order) {
    const star = getStar(card, 'favorite')
    hideStar(card, 'unfavorite')

    const handleCreatingFavorite = createFavoriteHandler(order, account, faves)
    on('create-favorite', 'submit', handleCreatingFavorite)(root)

    fromEvent(star, 'click').subscribe((_) => {
      modal.style.display = 'flex'
    })
  }

  /**
   * Reorder favorite or previous order
   *
   * @param element
   * @param order
   */
  function handleReorder (element: Element, order: any) {
    fromEvent(forTarget<HTMLLinkElement>(element, 'reorder-button')!, 'click')
      .subscribe((_event) => createBasketFromPreviousOrder(basket, account, order))
  }

  /**
   * When an order observable is changed, show the card for the order and attach the handlers
   */
  function subscribeToOrder () {
    order$.subscribe(async (order) => {
      if (!order) return
      const element = addOrderCardToHomepageFavorites(root, 'last-order-container', { ...order, name: 'Most Recent Order' })

      handlePreviousOrderActions(element, order)
      handleReorder(element, order)
    })
  }

  async function subscribeToAccount () {
    account.subscribe(async (data) => {
      data && await getOrderHistory()
    })
  }

return {
  start: mount(root,
      getOrderHistory,
      subscribeToPreviousOrders,
      subscribeToOrder,
      subscribeToAccount,
      on('close-new-favorite-modal', 'click', () => {
        modal.style.display = 'none'
      }),
    )
  }
}
