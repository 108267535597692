import { NoLocationError, ProductNotFoundError } from './customErrors';
import api, { ProductPayload } from './api';
import type { Record } from '~dk/store'

export type ImageGroup =
  "marketplace-product" |
  "mobile-app" |
  "mobile-app-large" |
  "mobile-webapp-customize" |
  "mobile-webapp-menu" |
  "responsive-large" |
  "desktop-menu" |
  "desktop-customize"

/**
 *
 * @param store
 * @param productId
 */
export function getProductDetails (store: any, productId: number) {
  const menu = store.get('menuItems')
  const product = menu["categories"]
    .flatMap((cat: any) => cat.products)
    .find((product: any) => product.id === productId)

  if (!product) {
    return new ProductNotFoundError('product not found')
  }

  return product
}

/**
 * Shows the location modal
 */
export function popLocationModal () {
  const link = document.querySelector('[dk-value="localize-link"]')! as HTMLLinkElement
  link.click()
}

/**
 * If the user doesn't have a location set, reject the promise with a
 * NoLocationFoundError
 */
export function getOrSetLocation (location: Record<any>): Promise<any> {
  return new Promise((resolve, reject) => {
    const locationData = location.getValue()

    if (!locationData) {
      popLocationModal()
      return reject('No location')
    }

    return resolve(locationData)
  })
}


/**
 * Searches for an existing OLO basket. If one exists return it, if not
 * create a new one
 */
export async function findOrCreateOloBasket (location: Record<any>, basket: Record<any>, account: Record<any>) {
  try {
    const locationData = location.getValue()
    const basketData = basket.getValue()
    const accountData = account.getValue() as Maybe<any>
    // const userData = user.getValue() as Maybe<any>

    if (basketData) return basket.next(basketData)
    if (!locationData) await getOrSetLocation(location)

    api.baskets.create(locationData.id, accountData?.authtoken).subscribe(
      ({ response }) => basket.next(response),
      (error) => console.error(error)
    )
  } catch (error) {
    if (error.name === 'NoLocationError') popLocationModal()
  }
}

/**
 * Adds a single product to the basket
 *
 * @param id
 * @param data
 * @param basket
 * @param root
 */
export function addSingleProductToBasket (id: any, data: ProductPayload, basket: Record<any>, root?: HTMLElement) {
  const payload = { ...data, quantity: 1 } as ProductPayload

  api.baskets.addItemToBasket(id, payload).subscribe(
    ({ response }) => basket.next(response),
    ({ response }) => alert(response.message)
  )
}

/**
 * Displays an alert on the form element
 *
 * @param root
 * @param message
 * @param alertType
 */
export function showAlert (root: HTMLElement, message: string, alertType = 'info')  {
  const alert = root.querySelector('[dk-target="alert"]')! as HTMLDivElement
  alertType === 'info' ? alert.classList.add('info') : alert.classList.remove('info')
  alert['innerHTML'] = message
  alert.style.display = 'block'
}

/**
 * Displays an alert on the form element
 *
 * @param root
 */
export function clearAlert (root: HTMLElement)  {
  const alert = root.querySelector('[dk-target="alert"]')! as HTMLDivElement
  alert.style.display = 'none'
}

/**
 * Hides the alert element
 *
 * @param root
 */
export function hideAlert (root: Element)  {
  const alert = root.querySelector('[dk-target="alert"]')! as HTMLDivElement
  alert.style.display = 'none'
}

/**
 * Gets an image for a given group
 *
 * @param images
 * @param groupName
 */
export function getImageByGroupName (images: any[], groupName: ImageGroup) {
 return images?.filter((image) => image.groupname === groupName)[0]
}

/**
 * Resizes an olo image
 * NOTE: You can't resize gifs
 *
 * @param filename
 * @param width
 * @param height
 */
export function resizeOloImage (filename: string, width: number, height: number) {
  return filename
  // if (filename.match(/.gif\?/)) {
  //   return filename.replace(/.gif\?.*$/, '.gif')
  // } else {
  //   return filename
  //     .replace(/w=[0-9]{2,3}/, `w=${width}`)
  //     .replace(/h=[0-9]{2,3}/, `h=${height}`)
  //     .replace('fit=fill', 'fit=cover')
  // }
}
