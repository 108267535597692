import { bootstrap } from '~dk/core'
import { createStore } from '~dk/store'
import Account from './modules/AccountInformation'
import Bag from './modules/Bag'
import CheckOut from './modules/CheckOut'
import CheckOutRewards from './modules/CheckOutRewards'
import Forms from './modules/Forms'
import GenghisRewards from './modules/GenghisRewards'
import GiftCards from './modules/GiftCards'
import Localization from './modules/Localization'
import Menu from './modules/Menu'
import Products from './modules/Products'
import Users from './modules/Users'
import OrderHistory from './modules/OrderHistory'
import OrderType from './modules/OrderType'
import BillingAccount from './modules/BillingAccount'
import QuickOrder from './modules/QuickOrder'
import Faves from './modules/Faves'
// import DeliveryAddress from './modules/DeliveryAddress'
import Coupon from './modules/Coupon'
import ThankYou from './modules/ThankYou'
import CurbsideCheckin from './modules/CurbsideCheckin'
import PaymentMethods from './modules/PaymentMethods'
import Logout from './modules/Logout'
import PreviousOrder from './modules/PreviousOrder'

const App = () => {
  const store = createStore()
  const persistentStore = createStore({}, 'dk', localStorage)

  const user = persistentStore.createRecord('user')
  const location = persistentStore.createRecord('location')
  const account = store.createRecord('account') // Olo user account
  const basket = store.createRecord('basket')
  const checkoutUser = store.createRecord('checkoutUser')
  const menu = store.createRecord('menu')
  const punchhLocations = store.createRecord('punchhLocations')
  const selection = store.createRecord('selection')
  const faves = store.createRecord('faves') // Olo user favorite menu items
  const faveLocations = store.createRecord('faveLocations') // Olo user's favorite locations
  const previousOrders = store.createRecord('previousOrders')
  const deliveryAddress = store.createRecord('deliveryAddress')
  const recentOrder = store.createRecord('recentOrder')
  const billingAccounts = store.createRecord('billingAccounts')
  const paymentMethods = store.createRecord('paymentMethods')
  const orderTypeVerified = store.createRecord('orderTypeVerified', false)

  function clearStores () {
    store.clear()
    persistentStore.clear()
  }

  bootstrap('app', Forms, { punchhLocations })
  bootstrap('bag', Bag, { menu, location, basket, selection, user, account })
  bootstrap('menu', Menu, { menu, location, basket, account, user })
  bootstrap('olo-localization', Localization, { location, faveLocations, basket })
  bootstrap('products', Products, { menu, selection, location, basket })
  bootstrap('rewards-sign-up', Users, { user })
  bootstrap('user', Users, { user, account, faveLocations })
  bootstrap('account', Account, { user, account, punchhLocations, location, menu, clearStores })
  bootstrap('genghis-rewards', GenghisRewards, { user })
  bootstrap('gift-cards', GiftCards, { user })
  bootstrap('order-history', OrderHistory, { account, previousOrders, faves, basket })
  bootstrap('quick-order', QuickOrder, { menu, location, basket, account })
  bootstrap('home-page-faves', [Faves, PreviousOrder], { account, faves, previousOrders, basket })
  bootstrap('thank-you', ThankYou, { recentOrder, checkoutUser })
  bootstrap('curbside-checkin', CurbsideCheckin, {})
  bootstrap(
    'check-out',
    [CheckOut, CheckOutRewards, OrderType, BillingAccount, Coupon, BillingAccount, PaymentMethods],
    { account, location, user, basket, checkoutUser, deliveryAddress, recentOrder, billingAccounts, paymentMethods, orderTypeVerified }
  )
  bootstrap('logout', Logout, { clearStores })
}

export default App
