import type { Record } from '~dk/store'
import { mount, on } from "~dk/core"
import api from '../lib/api'
import moment from 'moment'

interface Props {
  location: Record<any>
  basket: Record<any>
  faveLocations: Record<any>
}

export default ( root: HTMLElement, { location, basket, faveLocations }: Props) => {
  const template = document.querySelector('[data-dk-template="location-choice"]')!
  const locationContainer = document.querySelector('[data-dk-localization-options]')!

  /**
   * Initializes google places search
   */
  function initSearch () {
    const options = {
      types: ['address', 'postal_code'],
      componentRestrictions: { country: 'us' }
    }

    const input = root.querySelector('[dk-action="find-location"]')! as HTMLInputElement
    const service = new google.maps.places.SearchBox(input, options as any)

    service.addListener('places_changed', async () => {
      await getLocationFromPlacesSearch(service)
    })
  }

  /**
   * Sets the restaurants hours for a given record
   *
   * @param record
   * @param card
   */
  async function setRestaurantHours (record: any, card: HTMLElement) {
    const startDate = moment().format('YYYYMMDD')
    const endDate =  moment().add(6, 'days').format('YYYYMMDD')
    const formatTime = (time) => moment(time, 'YYYYMMDD HH:mm').format('ha')
    const { response } = await api.restaurants.calendars(record.id, startDate, endDate).toPromise()
    const { ranges } = response.calendar.find((calendar) => calendar.type === 'business')

    const schedules = ranges.map((range) => {
      return `${range.weekday}: ${formatTime(range.start)} &#8210; ${formatTime(range.end)}`
    })

    card.querySelector('[data-dk-value="store-hours"]')!.innerHTML = schedules.join('<br>')
  }

  /**
   * Add event listener so that when the user is forwarded to the menu when clicked
   *
   * @param card
   * @param record
   */
  function setCardActions (card: HTMLElement, record: any) {
    card.querySelector('.w-button')!.addEventListener('click', async () => {
      location.next(record)
      await transferBasketToNewLocation(record)

      window.location.pathname === '/menu'
      ? window.location.reload()
      : window.location.pathname = '/menu'
    })
  }

  /**
   * Builds a card for a given location
   *
   * @param record
   */
  async function setLocationCardContent (record: any) {
    const card = template.cloneNode(true) as HTMLElement

    await setRestaurantHours(record, card)

    card.classList.remove('u-hidden')
    card.querySelector('[data-dk-value="name"]')!.innerHTML = record.name
    card.querySelector('[data-dk-value="phone-number"]')!.innerHTML = record.telephone
    card.querySelector('[data-dk-value="address"]')!.innerHTML = `
          ${record.streetaddress}<br>
          ${record.city}, ${record.state} ${record.zip}
          `
    setCardActions(card, record)
    return card
  }

  /**
   * TODO: Added function description
   *
   * @param restaurants
   */
  async function addResultsToOptionsBlock (restaurants: any) {
    locationContainer.innerHTML = ''

    const cards = restaurants.map(async (record: any) => await setLocationCardContent(record))
    const resolvedCards = await Promise.all(cards) as HTMLElement[]

    resolvedCards.forEach((card) => locationContainer.append(card))
  }

  /**
   * If no restaurants are found. Clear the location card container and display a message
   */
  function displayNoResultsFound () {
    (locationContainer as HTMLDivElement).innerText = 'No restaurants found in this area'
  }

  /**
   * Uses the browser's native API to get the user's current geolocation
   *
   * @param e Click event for the given element
   */
  function getMyLocation (e: MouseEvent) {
    e.preventDefault()

    navigator.geolocation.getCurrentPosition(async ({ coords }) => {
      // const position = { lat: coords.latitude, lng: coords.longitude }
      api.restaurants.near(coords.latitude, coords.longitude).subscribe(({ response }) => {
        response.restaurants?.length
          ? addResultsToOptionsBlock(response.restaurants)
          : displayNoResultsFound()
      })
    })
  }

  /**
   * TODO: Added function description
   *
   * @param service
   */
  async function getLocationFromPlacesSearch (service: google.maps.places.SearchBox) {
    const place = service.getPlaces()[0]

    if (place?.geometry) {
      const lat = place.geometry.location.lat()
      const long = place.geometry.location.lng()

      api.restaurants.near(lat, long).subscribe(({ response }) => {
        addResultsToOptionsBlock(response.restaurants)
      })
    }
  }


  /**
   * Sets the location information inside of the "My Bag area"
   *
   * @param location
   */
  function setMyBagLocation (location: any) {
    const elem = document.querySelector("[data-dk-bag-location]")
    if (!elem) return

    elem.innerHTML = `
      <strong>${location.name}</strong><br>
      <span>${location.streetaddress}</span><br>
      <span>${location.city}, ${location.state} ${location.zip}</span>
    `
  }

  /**
   * Keeps the basket in-sync with the location
   *
   * @param record
   */
  function transferBasketToNewLocation (record: any) {
    const basketData = basket.getValue()
    if (!basketData || !record) return

    return api.baskets.transferBasketToDifferentRestaurant(basketData.id, record.id).toPromise()
      .then(({ response }) => basket.next(response.basket))
  }


  /**
   * Updates the text for the location link to match the name of the chosen location
   * `
   * @param location
   */
  function setLocationLinkText (location) {
    for (const link of document.querySelectorAll('[dk-value="localize-link"]') as any) {
      link.textContent = location.name
    }
  }

  /**
   * If fave locations is set but a location is not set, set the location to the favorite location
   */
  function subscribeToFavoriteLocations () {
    faveLocations.subscribe(async (data ) => {
      if (location.getValue() ||  !data) return

      try {
        const { vendorid } = (data as Maybe<any[]>)?.filter(location => location.isdefault)?.[0]
        const { response } = await api.restaurants.get(vendorid).toPromise()

        location.next(response)
      } catch(error) {
        return console.error('no favorite location set')
      }
    })
  }

  /**
   * Update main nav, if called for.
   * Only deals with catering for now.
   * 
   * Looks for data-hide-if-location="12345,23456"
   * where the numbers are location IDs.
   * 
   * Locations to hide on 4/26/2023:
   * - Bixby, OK: 15475
   * - Germantown, TN: 15484
   * 
   * Locations to hide on 5/9/2023:
   * - Reno, NV: 15471
   * - Dallas (Old Town), TX: 15488
   */
  function updateNavForLocation(location) {
    const currentUrl = window.location.hostname
    const demoUrl = 'genghis-grill.webflow.io'
    // if (currentUrl !== demoUrl) return // Uncomment to make active only on demo site
    const catering = <HTMLElement> document.getElementById('nav-catering')
    const noCateringLocations = <String> catering.dataset.hideIfLocation
    const noCateringArray = noCateringLocations.split(',')
    const noCateringNumberArray = noCateringArray.map(Number)
    if (noCateringNumberArray.includes(location.id)) {
      catering.classList.add('hidden')
    }
  }

  function subscribeToLocations () {
    location.subscribe(async (data) => {
      if (!data) return

      setLocationLinkText(data)
      setMyBagLocation(data)
      updateNavForLocation(data)
      // transferBasketToNewLocation(data)
    })
  }

  return {
    async onLoad () {
      initSearch()
      subscribeToLocations()
      subscribeToFavoriteLocations()
    },
    start: mount(root,
      on('get-location', 'click', getMyLocation),
      on('localize-form', 'submit', (e: Event) => e.preventDefault())
    )
  }
}


