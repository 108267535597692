import { fromEvent } from 'rxjs'
import { RootElement } from './watch';

export type ScopedModule = (
  rootElement: HTMLElement,
  props: any,
) => {
  start: Function
  onLoad?: Function
}

/**
 * Loads a module and calls the start function with props as its parameters
 * IMPORTANT: Lazy is very experimental, use at your own risk
 *
 * @param app
 * @param modules
 * @param props
 * @param lazy
 */
export const bootstrap = (app: string, modules: ScopedModule | ScopedModule[], props: any, lazy = false) => {
  const containers = document.querySelectorAll(`[dk-app="${app}"]`)
  handleOnload(modules, props)

  function loadModules (element: HTMLElement) {
    [modules].flat().forEach((module) => {
      module(element, props).start()
    })
  }

  if (!containers) {
    return // if the app container can't be found just return
  } else if (lazy) {
    containers.forEach((container) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const moduleLoaded = container.getAttribute('loaded') === 'true'
          if (entry.isIntersecting && !moduleLoaded) {
            container.setAttribute('loaded', 'true')
            return loadModules(container as HTMLElement)
          }
        })
      }, {})

      observer.observe(container)
    })
  } else {
    containers.forEach((element) => {
      loadModules(element as HTMLElement)
    })
  }
}

/**
 * Calls onLoad for each module allowing you to immediately invoke action when
 * the module is bootstrapped
 *
 * @param modules
 * @param props
 * @returns
 */
function handleOnload (modules: Maybe<Function | Function[]>, props: any) {
  if (!modules) return
  [modules].flat().forEach((module: Function) => {
    const mod = module(document, props)
    mod.onLoad && mod.onLoad()
  })
}

/**
 * Takes a list of functions and mounts each by passing in the root HTMLElement
 *
 * @param root
 * @param actions
 */
export const mount = (root: HTMLElement, ...actions: Maybe<Function>[]) => () => {
  actions.forEach((action) => action?.(root))
}

/**
 * Finds all DOM elements with the data attribute of dk-action="target", registers the
 * given event, and passes the event and the attributes value as parameters to
 * the provided action
 *
 * @param target
 * @param event
 * @param action
 * @param options
 */
export const on = (target: string, event: any, action: Function, options = {}) => (root: RootElement) => {
  const elements = root.querySelectorAll(`[dk-action="${target}"]`)

  elements?.forEach((element: HTMLElement) => {
    const values = element.dataset || {}
    fromEvent(element, event, options).subscribe((event) => action(event, { values }, element))
  })
}

export const forTarget = <T>(root: RootElement | Element, target: string): Maybe<T> => {
  return root.querySelector(`[dk-target="${target}"]`) as Maybe<T>
}

export const forTargets = <T>(root: RootElement, target: string): T[] => {
  const nodes = root.querySelectorAll(`[dk-target="${target}"]`)
  return Array.from(nodes) as unknown as T[]
}

export const combine = (...actions: Function[]) => () => {
  actions.forEach(action => action?.())
}

/**
 * Triggers an action immediately on load
 *
 * @param target
 * @param action
 */
export const trigger = (target: string, action: Function) => (root: HTMLElement) => {
  const elements = root.querySelectorAll(`[dk-target="${target}"]`)

  elements?.forEach((element: HTMLElement) => {
    const data = element.dataset[formatKey(target)]
    action(element, { data })
  })
}

/**
 * Transforms key from snake-case to camelCase and appends dk.
 * For example, a target of 'app' will apear as 'dkApp' in a target elements
 * dataset.
 *
 * @param target
 */
const formatKey = (target: string) => {
  return `dk-${target}`.replace(/-./g, (x) => x.toUpperCase()[1])
}
