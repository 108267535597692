/**
 * Converts the fields from a form element into an object where the keys are
 * derived from the input's *name*, and the values are taken from the input's value
 *
 * @param form
 */
export const formToObject = (form: HTMLFormElement): Object => {
  const obj = {}
  const fd = new FormData(form)

  fd.forEach((value, key) => (obj[key] = value))
  return obj
}

/**
 * Fetches an element from another page and places it inside of a parent element on the current page.
 *
 * @param url relative or absolute url for the document you want to load
 * @param target the CSS Selector for the target element
 * @param container the element on the current page which will contain the target
 * @param callback
 */
export const fetchRemoteElem = async (url: string, target: string, container: HTMLElement, callback?: Function) => {
  const data = await fetch(url)
  const html = await data.text()
  const parser = new DOMParser()
  const parsedHTML = parser.parseFromString(html, 'text/html')
  return parsedHTML.querySelector(target)
}

/**
 * Clears the contents of a given div
 *
 * @param element
 */
export function clearContents (element: HTMLElement) {
  element.innerHTML = ''
}
