import { forTarget, mount } from '~dk/core'
import {
  fetchMenu,
  handleBowlButton,
  navigateToProduct,
  setBowlContents,
  updateLocationIfSearchParamExists,
} from '../utils/menu'
import { findOrCreateOloBasket, getImageByGroupName, resizeOloImage } from '../lib/shared'
import type { Record } from '~dk/store'
import { fromTemplate } from '~dk/content'
import { fromEvent } from 'rxjs'
import camelCase from 'lodash.camelcase'
import { clearContents } from '~dk/helpers'

interface Props {
  location: Record<any>
  menu: Record<any>
  basket: Record<any>
  account: Record<any>
  user: Record<any>
}

export default ( root: HTMLElement, { menu, location, basket, account, user }: Props) => {
  const handleBowlButtonClicks = () => handleBowlButton(location, basket, account, root)
  let linkListElement: HTMLElement
  let menuSections: HTMLDivElement
  let imagePath: Maybe<string>

  /**
   * Add the values to each part of a product card
   * @param card
   * @param product
   */
  function setProductValues(card: HTMLDivElement, product) {
    card.style.display = 'block'
    card.querySelector('[dk-value="title"]')!.innerHTML = product.name
    card.querySelector('[dk-value="description"]')!.innerHTML = product.description.slice(0, 80) + '...'

    const price = card.querySelector('[dk-value="cost"]')!;

    price.innerHTML = '$' + (product.cost || 0).toFixed(2);
    card.setAttribute('dk-product', product.id);
    card.setAttribute('dk-action', 'product');

    // If calories are present, display the value.
    if (product.basecalories) {
      const calories = card.querySelector('[dk-value="calories"]')!;
      calories.innerHTML = 'Calories: ' + product.basecalories;
    }
  }

  /**
   * Gets the thumbnails for each menu item
   *
   * @param card
   * @param product
   */
  function setImageThumbnail(card: HTMLDivElement, product: any) {
    if (!product.images?.length) return

    const image = getImageByGroupName(product.images, 'mobile-webapp-menu')
    if (!image) return

    forTarget<HTMLDivElement>(card, 'product-image')!
      .setAttribute('src', imagePath + resizeOloImage(image.filename, 400, 300))
  }

  /**
   * Creates the product card to display in the menu
   *
   * @param product
   */
  function setProductContents (product) {
    const template = document.querySelector(`[dk-template="product"]`)!
    const element = template.cloneNode(true) as HTMLDivElement

    setProductValues(element, product)
    setImageThumbnail(element, product)

    return element
  }

  /**
   * Generates a cards for each product based on their type and appends them to the page
   *
   * @param products
   * @param category
   * @param section
   */
  function populateProducts (products, category, section: HTMLDivElement) {
    const categoryType =
      category.name === 'Create Your Own' ? 'bowl' :
      category.name === 'Chef Bowls' ? 'bowl' :
      category.name === 'Fried Rice' ? 'bowl' :
      'product'
    const container = section.querySelector(`[dk-container="menu-items"]`)!
    clearContents(container as HTMLElement)

    for (const product of products) {
      let card: HTMLDivElement

      if (categoryType === 'bowl') {
        card = setBowlContents(location, basket, product, { imagepath: imagePath }) as HTMLDivElement
        container.classList.add('--mobile-product-list') // Make things side scrollable
        // fromEvent(card, 'click').subscribe(() => navigateToProduct(location, product.id)) // Make card clickable
      } else {
        card = setProductContents(product)
        container.classList.add('--mobile-product-list') // Make things side scrollable
        fromEvent(card, 'click').subscribe(() => navigateToProduct(location, product.id)) // Make card clickable
      }

      container.append(card)
    }
  }

  /**
   * Updates the top nav links to include a link for each category
   *
   * @param categoryId
   * @param category
   * @param menuBar
   */
  function setMenuLinks (categoryId: string, category: any, menuBar: Element) {
    const listItem = linkListElement.cloneNode(true)! as HTMLElement
    const link = listItem.querySelector('a')!

    link.href = `#${categoryId}`
    link.innerText = category.name
    link.classList.remove('w--current')

    menuBar.append(link)
  }

  /**
   * Sets the menu items for products in each category
   *
   * @param categories
   */
  function setMenuItemsBasedOnCategories ({ categories }) {
    if (!categories) return

    const menuBar = forTarget<HTMLDivElement>(root, 'menu-nav')!
    linkListElement ||= forTarget<HTMLDivElement>(root, 'menu-nav-link')!
    menuSections ||= forTarget<HTMLDivElement>(root, 'menu-sections')!
    menuBar.innerHTML = ''
    menuSections.innerHTML = ''
    linkListElement.style.display = 'block'

    for (const category of categories) {
      const categoryId = camelCase(category.name)
      setMenuLinks(categoryId, category, menuBar)

      switch (category.name) {
        case 'Valuebowls':
          category['description'] = '$7.99 VALUEBOWLS — Fast, fresh, delicious bowls — all served on steamed white rice, all under $8!'
          break
        case 'Fried Rice':
          category['description'] = 'Can you keep a secret? Nah, we can’t either. This is the best fried rice in America. Period!'
          break
        case 'Chef Bowls':
          category['description'] = 'Let our bowl experts make it for you from 12 of our best recipes'
          break
        case 'Create Your Own':
          category['description'] = 'Create your own bowl from over 80+ of the freshest ingredients available.'
          break
        default:
          category['description'] = '';
      }

      const categorySection = fromTemplate('menu-section', category) as HTMLDivElement
      categorySection.id = categoryId
      populateProducts(category.products, category, categorySection)
      menuSections.append(categorySection)
    }

    menuBar.querySelector('a')!.classList.add('w--current')
  }

  /**
   * subscribe to menu changes
   */
  function subscribeToMenu () {
    return menu.subscribe((data: Maybe<{ imagepath: Maybe<string>, categories: any[] }>) => {
      if (!data) return
      imagePath = data.imagepath
      setMenuItemsBasedOnCategories(data)
    })
  }

  return {
    start: mount(root,
      () => updateLocationIfSearchParamExists(location), 
      () => fetchMenu (location, menu, async () => {
        await handleBowlButtonClicks()
      }),
      handleBowlButtonClicks,
      subscribeToMenu,
      async () => await findOrCreateOloBasket(location, basket, account),
    )
  }
}
